import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface SelectedCategoriesInterface {
  [key: number]: boolean
}

interface SelectedCategoriesContextInterface {
  selectedCategoriesState: SelectedCategoriesInterface
  setSelectedCategoriesState: React.Dispatch<React.SetStateAction<SelectedCategoriesInterface>>
}

const defaultState = {}

export const SelectedCategoriesContext = createContext<SelectedCategoriesContextInterface>({
  selectedCategoriesState: defaultState,
  setSelectedCategoriesState: () => {},
})

export const SelectedCategoriesContextProvider = (props: { children: React.ReactNode }) => {
  const [selectedCategoriesState, setSelectedCategoriesStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["selectedCategoriesStateChrome"], (result) => {
        if (result.selectedCategoriesStateChrome) {
          setSelectedCategoriesStatePrivate(result.selectedCategoriesStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ selectedCategoriesStateChrome: selectedCategoriesState })
    }
  }, [selectedCategoriesState])

  // Update state
  const setSelectedCategoriesState = useCallback((newState) => {
    setSelectedCategoriesStatePrivate(newState)
  }, [])

  return (
    <SelectedCategoriesContext.Provider
      value={{
        selectedCategoriesState,
        setSelectedCategoriesState,
      }}
    >
      {props.children}
    </SelectedCategoriesContext.Provider>
  )
}
