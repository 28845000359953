import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface PersonalLinkCardContextInterface {
  personalLinkCardState: boolean
  setPersonalLinkCardState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = true

export const PersonalLinkCardContext = createContext<PersonalLinkCardContextInterface>({
  personalLinkCardState: defaultState,
  setPersonalLinkCardState: () => {},
})

export const PersonalLinkCardContextProvider = (props: { children: React.ReactNode }) => {
  const [personalLinkCardState, setPersonalLinkCardStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["personalLinkCardStateChrome"], (result) => {
        if (result.personalLinkCardStateChrome) {
          setPersonalLinkCardStatePrivate(result.personalLinkCardStateChrome.isOpen) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ personalLinkCardStateChrome: { isOpen: personalLinkCardState } })
    }
  }, [personalLinkCardState])

  // Update state
  const setPersonalLinkCardState = useCallback((newState) => {
    setPersonalLinkCardStatePrivate(newState)
  }, [])

  return (
    <PersonalLinkCardContext.Provider
      value={{
        personalLinkCardState,
        setPersonalLinkCardState,
      }}
    >
      {props.children}
    </PersonalLinkCardContext.Provider>
  )
}
