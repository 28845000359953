import { createRef } from "react"
import useFileUpload from "./useFileUpload"

import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import { SxProps } from "@mui/material"
import { ActiveUserWithResourcesInterface } from "interfaces/User"
import { useIsExtension } from "Utils/Utils"

interface ImportBookmarksTextAndButtonInterface {
  textStyle: SxProps
  activeUser?: ActiveUserWithResourcesInterface
}

export const ImportBookmarksTextAndButton = ({ textStyle, activeUser }: ImportBookmarksTextAndButtonInterface) => {
  const { handleFileUpload } = useFileUpload()
  const fileUploadRef = createRef<any>()
  const isExtension = useIsExtension()
  const handleFileChange = () => {
    handleFileUpload(fileUploadRef.current.files[0])
    fileUploadRef.current.value = ""
  }

  const importBookmarksRemaining = () => {
    if (!activeUser) {
      return ""
    }
    if (activeUser.hasActiveSubscription) {
      return (
        <Typography sx={textStyle} variant="subtitle2">
          You may save {activeUser?.maxLinkCount - activeUser?.linkCount} more links.
        </Typography>
      )
    }
    return (
      <Typography sx={textStyle} variant="subtitle2">
        You may save {activeUser?.maxLinkCount - activeUser?.linkCount} more links. Please{" "}
        <Link href={`${process.env.PUBLIC_URL}/upgrade`}>Upgrade your account</Link> if you need more storage.
      </Typography>
    )
  }

  const uploadButton = () => {
    if (isExtension) {
      return (
        <Button
          variant="outlined"
          aria-label="upload-bookmarks"
          onClick={() => window.open(`${process.env.PUBLIC_URL}/account`)}
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Manage on linkidex.com
        </Button>
      )
    } else {
      return (
        <Button variant="outlined" aria-label="upload-bookmarks" sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}>
          Upload File
          <input type="file" hidden onChange={handleFileChange} ref={fileUploadRef} />
        </Button>
      )
    }
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          Import Bookmarks
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          Upload a ".html" file generated by your browser to Linkidex.
        </Typography>
        {importBookmarksRemaining()}
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        {uploadButton()}
      </Grid>
    </>
  )
}
