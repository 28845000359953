import React from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Link from "@mui/material/Link"

export const TermsOfServicePage = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" id="terms-of-service">
        Terms of Service
      </Typography>
      <Typography>
        <em>Last Updated: Jan 22, 2023</em>
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="the-gist">
        The Gist
      </Typography>
      <Typography>
        These Terms of Service (“Terms”) describe our (the team at Linkidex) commitments to you, and your rights and
        responsibilities when using our services. Please read them carefully and reach out to us if you have any
        questions.{" "}
        <strong>
          These Terms include a mandatory arbitration provision in Section 14. If you don't agree to these Terms, don't
          use our services.
        </strong>
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="terms-of-service">
        Terms of Service
      </Typography>
      <Typography>
        These Terms govern your access to and use of the products and services we provide through or for Linkidex.com,
        including mobile applications and browser extensions (collectively, “Services”). Please read these Terms
        carefully before accessing or using our Services. By accessing or using any part of our Services, you agree to
        be bound by all of the Terms and all other operating rules, policies, and procedures that we may publish via the
        Services from time to time (collectively, the “Agreement”). You also agree that we may automatically change,
        update, or add on to our Services, and this Agreement will apply to any changes.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="1-who-s-who">
        1. Who's Who
      </Typography>
      <Typography>
        “You” means any individual or entity using our Services. If you use our Services on behalf of another person or
        entity, you represent and warrant that you're authorized to accept the Agreement on that person's or entity's
        behalf, that by using our Services you&#39;re accepting the Agreement on behalf of that person or entity, and
        that if you, or that person or entity, violates the Agreement, you and that person or entity agree to be
        responsible to us, Linkidex.
      </Typography>
      <Typography>We refer to Linkidex Inc. as “Linkidex” or “we” throughout these Terms.</Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="2-your-account">
        2. Your Account
      </Typography>
      <Typography>
        When using our Services requires an account, you agree to provide us with complete and accurate information and
        to keep the information current so that we can communicate with you about your account. We may need to send you
        emails about notable updates (like changes to our Terms of Service or{" "}
        <Link href={`${process.env.PUBLIC_URL}/privacy_policy`}>Privacy Policy</Link>), or to let you know about legal
        inquiries or complaints we receive about the ways you use our Services so you can make informed choices in
        response. We may limit your access to our Services until we're able to verify your account information, like
        your email address. When you create a Linkidex.com account, we consider that to be an inquiry about our products
        and services, which means that we may also contact you to share more details about what we have to offer (i.e.,
        marketing). Don't worry — if you aren't interested, you can opt out of the marketing communications, whether
        it's an email, phone call, or text message. You're solely responsible and liable for all activity under your
        account. You're also fully responsible for maintaining the security of your account (which includes keeping your
        password secure). We're not liable for any acts or omissions by you, including any damages of any kind incurred
        as a result of your acts or omissions. If you get fired because of a blog post you write about your boss, that's
        on you. Don't share or misuse your access credentials. And notify us immediately of any unauthorized uses of
        your account, or of any other breach of security. If we believe your account has been compromised, we may
        suspend or disable it. If you&#39;d like to learn about how we handle the data you provide us, please see our{" "}
        <Link href={`${process.env.PUBLIC_URL}/privacy_policy`}>Privacy Policy</Link>.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="3-minimum-age-requirements">
        3. Minimum Age Requirements
      </Typography>
      <Typography>
        Our Services are not directed to children. You're not allowed to access or use our Services if you're under the
        age of 13 (or 16 in Europe). If you register as a user or otherwise use our Services, you represent that you're
        at least 13 (or 16 in Europe). You may use our Services only if you can legally form a binding contract with us.
        In other words, if you're under 18 years of age (or the legal age of majority where you live), you can only use
        our Services under the supervision of a parent or legal guardian who agrees to the Agreement.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="4-responsibility-of-visitors-and-users">
        4. Responsibility of Visitors and Users
      </Typography>
      <Typography>
        We haven't reviewed, and can't review, all of the content (like text, photo, video, audio, code, computer
        software, items for sale, and other materials) posted to or made available through our Services by users or
        anyone else (“Content”) or on websites that link to, or are linked from, our Services. We're not responsible for
        any use or effects of Content or third-party websites. So, for example:
      </Typography>
      <ul>
        <li>
          <Typography>We don't have any control over third-party websites.</Typography>
        </li>
        <li>
          <Typography>
            A link to or from one of our Services does not represent or imply that we endorse any third-party website.
          </Typography>
        </li>
        <li>
          <Typography>
            We don't endorse any Content or represent that Content is accurate, useful, or not harmful. Content could be
            offensive, indecent, or objectionable; include technical inaccuracies, typographical mistakes, or other
            errors; or violate or infringe the privacy, publicity rights, intellectual property rights, or other
            proprietary rights of third parties.
          </Typography>
        </li>
        <li>
          <Typography>
            You're fully responsible for the Content and Links available on your account, and any harm resulting from
            that Content. It's your responsibility to ensure that the content you add to Linkidex abides by applicable
            laws and by the Agreement.
          </Typography>
        </li>
        <li>
          <Typography>
            We aren't responsible for any harm resulting from anyone's access, use, purchase, or downloading of Content,
            or for any harm resulting from third-party websites. You're responsible for taking the necessary precautions
            to protect yourself and your computer systems from viruses, worms, Trojan horses, and other harmful or
            destructive content.
          </Typography>
        </li>
        <li>
          <Typography>
            We are not a party to, and will have no responsibility or liability for, any communications, transactions,
            interactions, or disputes between you and the provider of any Content.
          </Typography>
        </li>
      </ul>
      <Typography>
        Please note that additional third-party terms and conditions may apply to Content you download, copy, purchase,
        or use.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="5-fees-payment-and-renewal">
        5. Fees, Payment, and Renewal
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }} id="a-Linkidex-fees">
        a. Linkidex Fees
      </Typography>
      <Typography>
        <strong>Fees for Paid Services:</strong> Some of our Services are offered for a fee (collectively, “Paid
        Services”). By using a Paid Service, you agree to pay the specified fees. The amount of fees can vary depending
        on the number of seats you have purchased. Depending on the Paid Service, there may be one-time fees or
        recurring fees. For recurring fees, we'll bill or charge you in the automatically-renewing interval (such as
        monthly, annually, or biennially) you select, on a pre-pay basis until you cancel.
        <br />
        <br />
        <strong>Cancellation & Termination:</strong> You can cancel your subscription at any time. To cancel your
        subscription, navigate to your organization on your{" "}
        <Link href="https://www.linkidex.com/organizations" target="_blank" rel="noreferrer">
          organizations page
        </Link>{" "}
        and click 'edit subscription'. You are solely responsible for canceling your own subscription. Emailing or
        calling Linkidex is not considered cancelation.
        <br />
        <br />
        <strong>Adding and Removing Seats.</strong> You can edit the number of seats you have purchased at any time by
        navigating to your organization on your{" "}
        <Link href="https://www.linkidex.com/organizations" target="_blank" rel="noreferrer">
          organizations page
        </Link>{" "}
        and click 'edit subscription'. If you add seats, you will be charged a prorated fee for the additional seats
        based on your subscription's automatically-renewing interval. If you remove seats, A proration credit for the
        time remaining will be applied to your next invoice. For example, if a seat cost $5.00 a month, and halfway
        through the month you remove one of your organization's seats, you will have a $2.50 discount on your next
        invoice. We do not refund this credit if you cancel your subscription.
        <br />
        <br />
        <strong>Taxes:</strong> To the extent permitted by law, or unless explicitly stated otherwise, all fees do not
        include applicable federal, provincial, state, local or other governmental sales, value-added, goods and
        services, harmonized or other taxes, fees, or charges (“Taxes”). You're responsible for paying all applicable
        Taxes relating to your use of our Services, your payments, or your purchases. If we're obligated to pay or
        collect Taxes on the fees you've paid or will pay, you're responsible for those Taxes, and we may collect
        payment.
        <br />
        <br />
        <strong>Payment:</strong> If your payment fails, Paid Services are otherwise not paid for or paid for on time
        (for example, if you contact your bank or credit card company to decline or reverse the charge of fees for Paid
        Services), or we suspect a payment is fraudulent, we may immediately cancel or revoke your access to Paid
        Services without notice to you.
        <br />
        <br />
        <strong>Automatic Renewal:</strong> To ensure uninterrupted service, recurring Paid Services are automatically
        renewed. This means that unless you cancel a Paid Service before the end of the applicable subscription period,
        it will automatically renew, and you authorize us to use any payment mechanism we have on record for you, like
        credit cards or PayPal, or invoice you (in which case payment is due within 15 days) to collect the
        then-applicable subscription fee as well as any Taxes. By default, your Paid Services will be renewed for the
        same interval as your original subscription period, so for example, if you purchase a one-year subscription to a
        Linkidex.com plan, you'll be charged each year for access for another 12-month period. We may charge your
        account up to one month before the end of the subscription period to make sure pesky billing issues don't
        inadvertently disrupt your access to our Services. The date for the automatic renewal is based on the date of
        the original purchase and cannot be changed.
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="6-feedback">
        6. Feedback
      </Typography>
      <Typography>
        We love hearing from you and are always looking to improve our Services. When you share comments, ideas, or
        feedback with us, you agree that we&#39;re free to use them without any restriction or compensation to you.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="7-general-representation-and-warranty">
        7. General Representation and Warranty
      </Typography>
      <Typography>You represent and warrant that your use of our Services:</Typography>
      <ul>
        <li>
          <Typography>Will be in strict accordance with the Agreement;</Typography>
        </li>
        <li>
          <Typography>
            Will comply with all applicable laws and regulations (including, without limitation, all applicable laws
            regarding online conduct and acceptable content, privacy, data protection, the transmission of technical
            data exported from the United States or the country in which you reside, the use or provision of financial
            services, notification and consumer protection, unfair competition, and false advertising);
          </Typography>
        </li>
        <li>
          <Typography>
            Will not be for any unlawful purposes, to publish illegal content, or in furtherance of illegal activities;
          </Typography>
        </li>
        <li>
          <Typography>
            Will not infringe or misappropriate the intellectual property rights of Linkidex or any third party;
          </Typography>
        </li>
        <li>
          <Typography>
            Will not overburden or interfere with our systems or impose an unreasonable or disproportionately large load
            on our infrastructure, as determined by us in our sole discretion;
          </Typography>
        </li>
        <li>
          <Typography>Will not disclose the personal information of others;</Typography>
        </li>
        <li>
          <Typography>Will not be used to send spam or bulk unsolicited messages;</Typography>
        </li>
        <li>
          <Typography>Will not interfere with, disrupt, or attack any service or network;</Typography>
        </li>
        <li>
          <Typography>
            Will not be used to create, distribute, or enable material that is, facilitates, or operates in conjunction
            with, malware, spyware, adware, or other malicious programs or code;
          </Typography>
        </li>
        <li>
          <Typography>
            Will not involve reverse engineering, decompiling, disassembling, deciphering, or otherwise attempting to
            derive the source code for the Services or any related technology that is not open source; and
          </Typography>
        </li>
        <li>
          <Typography>
            Will not involve renting, leasing, loaning, selling, or reselling the Services or related data without our
            consent.
          </Typography>
        </li>
      </ul>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="8-specific-service-terms">
        8. Specific Service Terms
      </Typography>
      <Typography variant="h6" sx={{ mt: 2, mb: 2 }} id="a-Linkidex-com-websites-and-accounts">
        a. Linkidex.com Websites and Accounts
      </Typography>
      <Typography>
        Linkidex.com enables you to save, organize, and share links to your favorite parts of the internet, and we would
        love for you to use it. A Linkidex.com account also allows you to sign into some of our other Services.
        Linkidex.com's basic service is free, and we offer paid plans that add advanced features. Be responsible in what
        you publish. In particular, make sure that nothing prohibited (like spam, viruses, or serious threats of
        violence) appears on your website. If you find something on Linkidex.com that you believe violates these Terms,
        please let us know my email help@linkidex.com.
        <br />
        <br />
        <strong>License:</strong> By uploading or sharing Content, you grant us a worldwide, royalty-free, transferable,
        sub-licensable, and non-exclusive license to use, reproduce, modify, distribute, adapt, publicly display, and
        publish the Content solely for the purpose of providing and improving our products and Services. This license
        also allows us to make any publicly-posted Content available to select third parties so that these third parties
        can analyze and distribute (but not publicly display) the Content through their services. You also give other
        Linkidex.com users permission to share your Content and add their own Content to it.
        <br />
        <br />
        <strong>Removing Content:</strong> If you delete Content, we'll use reasonable efforts to remove it from public
        view on Linkidex.com, but you acknowledge that cached versions of the Content or references to the Content may
        not be immediately unavailable.
        <br />
        <br />
        <strong>Prohibited Uses:</strong> Your Content and conduct must not violate the{" "}
        <Link href={`${process.env.PUBLIC_URL}/user_policy`}>Linkidex.com User Guidelines</Link>.
      </Typography>

      <Typography variant="h6" sx={{ mt: 2, mb: 2 }} id="a-Linkidex-com-websites-and-accounts">
        b. Artificial Intelligence
      </Typography>
      <Typography>
        We may offer features that incorporate artificial intelligence (AI), (like category and tag suggestion) that
        enable you to use AI to generate content or images.
        <br />
        <br />
        As between you and Linkidex, and to the extent permitted by law, you own or have the rights to any content you
        input into AI features (”Input”) and any content generated by the AI features (”Output”).
        <br />
        <br />
        You're responsible for your Input, the use of any Output, and for complying with any applicable laws. AI may
        occasionally generate Output that's inaccurate, irrelevant, offensive, harmful, or similar to others' content.
        Please see our <Link href={`${process.env.PUBLIC_URL}/ai_guidelines`}>AI Guidelines</Link> for the policies you
        must comply with when you use AI features, details about the third-party providers we use to provide AI
        features, and helpful information about AI generally.
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="9-intellectual-property">
        9. Intellectual Property
      </Typography>
      <Typography>
        The Agreement doesn't transfer any Linkidex or third-party intellectual property to you, and all right, title,
        and interest in and to such property remains (as between Linkidex and you) solely with Linkidex. Linkidex Inc.,
        Linkidex, Linkidex.com, and all other trademarks, service marks, graphics, and logos used in connection with our
        websites or Services are trademarks or registered trademarks of Linkidex (or Linkidex's licensors). Other
        trademarks, service marks, graphics, and logos used in connection with our Services may be the trademarks of
        other third parties. Using our Services doesn't grant you any right or license to reproduce or otherwise use any
        Linkidex or third-party trademarks.
      </Typography>

      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="10-copyright">
        10. Copyright
      </Typography>
      <Typography>
        As we ask others to respect our intellectual property rights, we respect the intellectual property rights of
        others. If you believe any Content violates your copyright, please see our{" "}
        <Link href={`${process.env.PUBLIC_URL}/dmca`}>Copyright Policy</Link> and send us a notice.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="11-changes">
        11. Changes
      </Typography>
      <Typography>
        We may update, change, or discontinue any aspect of our Services at any time. Since we're constantly updating
        our Services, we sometimes have to change the legal terms under which they're offered. The Agreement may only be
        modified by a written amendment signed by an authorized executive of Linkidex, or if Linkidex posts a revised
        version. We'll let you know when there are changes: we'll post them here and update the “Last Updated” date, and
        we may also post on one of our blogs or send you an email or other communication before the changes become
        effective. Your continued use of our Services after the new terms take effect will be subject to the new terms,
        so if you disagree with the changes in the new terms, you should stop using our Services. To the extent you have
        an existing subscription, you may be eligible for a refund.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="12-termination">
        12. Termination
      </Typography>
      <Typography>
        We may terminate your access to all or any part of our Services at any time, with or without cause, with or
        without notice, effective immediately. We have the right (though not the obligation) to, in our sole discretion,
        (i) reclaim your username due to prolonged inactivity, (ii) refuse or remove any content that, in our reasonable
        opinion, violates any the Agreement or any Linkidex policy, or is in any way harmful or objectionable, (iii) ask
        you to make adjustments or terminate your access to the Services, or (iv) terminate or deny access to and use of
        any of our Services to any individual or entity for any reason. We will have no obligation to provide a refund
        of any fees previously paid. You can stop using our Services at any time, or, if you use a Paid Service, you can
        cancel at any time, subject to the Fees, Payment, and Renewal section of these Terms.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="13-disclaimers">
        13. Disclaimers
      </Typography>
      <Typography>
        Our Services are provided “as is.” Linkidex and its suppliers and licensors hereby disclaim all warranties of
        any kind, express or implied, including, without limitation, the warranties of merchantability, fitness for a
        particular purpose and non-infringement. Neither Linkidex, nor its suppliers and licensors, makes any warranty
        that our Services will be error free or that access thereto will be continuous or uninterrupted. You understand
        that you download from, or otherwise obtain content or services through, our Services at your own discretion and
        risk.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="14-jurisdiction-and-applicable-law-">
        14. Jurisdiction and Applicable Law.
      </Typography>
      <Typography>
        Except to the extent any applicable law provides otherwise, the Agreement and any access to or use of our
        Services will be governed by the laws of the state of California, U.S.A., excluding its conflict of law
        provisions. The proper venue for any disputes arising out of or relating to the Agreement and any access to or
        use of our Services that are not otherwise subject to arbitration (as indicated below) will be the state and
        federal courts located in San Francisco County, California.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="15-arbitration-agreement">
        15. Arbitration Agreement
      </Typography>
      <Typography>
        Except for claims for injunctive or equitable relief or claims regarding intellectual property rights (which may
        be brought in any competent court without the posting of a bond), any dispute arising under the Agreement shall
        be finally settled in accordance with the Comprehensive Arbitration Rules of the Judicial Arbitration and
        Mediation Service, Inc. (“JAMS”) by three arbitrators appointed in accordance with such Rules. The arbitration
        shall take place in San Francisco, California, in the English language and the arbitral decision may be enforced
        in any court. The prevailing party in any action or proceeding to enforce the Agreement shall be entitled to
        costs and attorneys' fees.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="16-limitation-of-liability">
        16. Limitation of Liability
      </Typography>
      <Typography>
        In no event will Linkidex, or its suppliers, partners, or licensors, be liable (including for any third-party
        products or services purchased or used through our Services) with respect to any subject matter of the Agreement
        under any contract, negligence, strict liability or other legal or equitable theory for: (i) any special,
        incidental or consequential damages; (ii) the cost of procurement for substitute products or services; (iii) for
        interruption of use or loss or corruption of data; or (iv) for any amounts that exceed $50 or the fees paid by
        you to Linkidex under the Agreement during the twelve (12) month period prior to the cause of action, whichever
        is greater. Linkidex shall have no liability for any failure or delay due to matters beyond its reasonable
        control. The foregoing shall not apply to the extent prohibited by applicable law.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="17-indemnification">
        17. Indemnification
      </Typography>
      <Typography>
        You agree to indemnify and hold harmless Linkidex, its contractors, and its licensors, and their respective
        directors, officers, employees, and agents from and against any and all losses, liabilities, demands, damages,
        costs, claims, and expenses, including attorneys' fees, arising out of or related to your use of our Services,
        including but not limited to your violation of the Agreement or any agreement with a provider of third-party
        services used in connection with the Services, Content that you post, and any activities conducted through your
        or another user's content.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="18-us-economic-sanctions">
        18. US Economic Sanctions
      </Typography>
      <Typography>
        You agree that our ability to provide our Services to you is subject to compliance with US sanctions that
        restrict or prohibit who or from where our Services can be accessed. By using the Services, you represent and
        warrant that you (i) are not located or residing in any country or territory subject to comprehensive US
        sanctions (currently Cuba, Crimea, Iran, North Korea, Donetsk People's Republic, Luhansk People's Republic, and
        Syria); (ii) are not listed on any United States list of prohibited or restricted parties, such as{" "}
        <Link href="https://home.treasury.gov/policy-issues/financial-sanctions/specially-designated-nationals-and-blocked-persons-list-sdn-human-readable-lists">
          OFAC's Specially Designated Nationals and Persons List
        </Link>
        , or otherwise subject to US sanctions that would prohibit your access to or use of our Services; and (iii)
        shall not use or allow access by any of your customers or potential customers (if applicable) to the Services in
        any manner that may cause Linkidex to violate US export controls and sanctions. We reserve the right to restrict
        or block your access, or access by any of your customers or potential customers (if applicable), to the Services
        and/or to terminate the Agreement at any time without notice if we determine, at our sole discretion, that such
        access may cause a violation or create unacceptable risk to us under export controls or sanctions.
      </Typography>
      <Typography variant="h5" sx={{ mt: 2, mb: 2 }} id="19-miscellaneous">
        19. Miscellaneous
      </Typography>
      <Typography>
        The Agreement (together with any other terms we provide that apply to any specific Service) constitutes the
        entire agreement between Linkidex and you concerning our Services. If any part of the Agreement is unlawful,
        void, or unenforceable, that part is severable from the Agreement, and does not affect the validity or
        enforceability of the rest of the Agreement. A waiver by either party of any term or condition of the Agreement
        or any breach thereof, in any one instance, will not waive such term or condition or any subsequent breach
        thereof. Linkidex may assign its rights under the Agreement without condition. You may only assign your rights
        under the Agreement with our prior written consent.
      </Typography>
      <br />

      <Typography variant="h4" sx={{ mt: 2, mb: 2 }} id="change-log">
        Change log
      </Typography>
      <ul>
        <li>
          <Typography>
            <em>May 30, 2022</em>: Original ToS Published. Hello world!
          </Typography>
        </li>
        <li>
          <Typography>
            <em>Jan 22, 2023</em>: Added DMCA Section.
          </Typography>
        </li>
      </ul>

      <Typography variant="caption">
        Thank you to the team at <Link href="https://auttomatic.com/about/">Auttomatic</Link> for making their Terms of
        Service available under a{" "}
        <Link href="https://creativecommons.org/licenses/by-sa/4.0/">Creative Commons Sharealike</Link> License. You can
        grab a copy of their Terms of Service and other legal documents on{" "}
        <Link href="https://github.com/Automattic/legalmattic/" target="_blank" rel="noreferrer">
          GitHub
        </Link>
        .
      </Typography>
    </Box>
  )
}
