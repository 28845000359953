import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface FavoritesSelectedContextInterface {
  favoritesSelectedState: boolean
  setFavoritesSelectedState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const FavoritesSelectedContext = createContext<FavoritesSelectedContextInterface>({
  favoritesSelectedState: defaultState,
  setFavoritesSelectedState: () => {},
})

export const FavoritesSelectedContextProvider = (props: { children: React.ReactNode }) => {
  const [favoritesSelectedState, setFavoritesSelectedStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["favoritesSelectedStateChrome"], (result) => {
        if (result.favoritesSelectedStateChrome) {
          setFavoritesSelectedStatePrivate(result.favoritesSelectedStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ favoritesSelectedStateChrome: favoritesSelectedState })
    }
  }, [favoritesSelectedState])

  // Update state
  const setFavoritesSelectedState = useCallback((newState) => {
    setFavoritesSelectedStatePrivate(newState)
  }, [])

  return (
    <FavoritesSelectedContext.Provider
      value={{
        favoritesSelectedState,
        setFavoritesSelectedState,
      }}
    >
      {props.children}
    </FavoritesSelectedContext.Provider>
  )
}
