import { useContext } from "react"
import { useTheme } from "@mui/material/styles"
import { useNavigate } from "react-router-dom"

import { MODAL_TYPES } from "../Modal/ModalTypes"
import { LightDarkSlider } from "components/LightDarkSlider"

// Contexts
import { ModalContext } from "../../contexts/ModalContext"
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// MUI
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Link from "@mui/material/Link"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import CampaignIcon from "@mui/icons-material/Campaign"
import NewspaperIcon from "@mui/icons-material/Newspaper"

export function LandingHowTo() {
  const { setModalState } = useContext(ModalContext)
  const { currentModeState } = useContext(LightDarkModeContext)
  const theme = useTheme()
  const navigate = useNavigate()

  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  const LightOrDark = () => {
    if (isDarkModeEnabled) {
      return "Dark"
    }
    return "Light"
  }

  const openSignUpModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.SIGN_UP })
  }

  const VERTICAL_SPACING_MOBILE = 2
  const VERTICAL_SPACING_DESKTOP = 1

  // common sx for all H3s on landing page. save, sort, search headers, etc.
  const h3TypographySX = {
    margin: "auto",
    mt: { xs: VERTICAL_SPACING_MOBILE, md: VERTICAL_SPACING_DESKTOP },
    pb: 1,
  }

  // common sx for descriptions underneath the H3s
  const descriptionSx = {
    lineHeight: "36px",
  }

  // common sx for all images on landing page
  const imageSx = {
    margin: "auto",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.divider,
    borderRadius: "25px",
  }

  const bottomOfGradient = () => {
    if (isDarkModeEnabled) {
      return "#242424"
    }
    return "#FFFFFF"
  }

  const topOfGradient = () => {
    if (isDarkModeEnabled) {
      return "#353535"
    }
    return "#F2F2F2"
  }

  const gradientBoxSx = {
    px: 4,
    py: 2,
    borderRadius: "25px",
    radius: "0px",
    backgroundImage: {
      xs: `linear-gradient(to bottom, ${topOfGradient()}, ${bottomOfGradient()} 40%)`,
      md: `linear-gradient(to bottom, ${topOfGradient()}, ${bottomOfGradient()} 40%)`,
    },
  }

  const saveUrls = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography variant="h3" sx={{ ...h3TypographySX }}>
            <Box
              component="span"
              sx={{ color: isDarkModeEnabled ? theme.palette.secondary.dark : theme.palette.secondary.light }}
            >
              {" "}
              Save{" "}
            </Box>
            Important URLs
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            With Linkidex, saving your favorite URLs is easy. Our{" "}
            <Link href="https://www.linkidex.com/blog/posts/open-graph-meta-tags/">Autofill feature </Link>
            automatically populates a link's title and description, and our{" "}
            <Link href="https://www.linkidex.com/blog/posts/ai-suggestions/">Ai Suggestions</Link> can generate
            categories and tags, keeping you organized and saving you time.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            width: { xs: "70%", md: "80%" },
            ...imageSx,
          }}
          alt="Linkidex Create Link"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexCreateLink${LightOrDark()}.png`}
        />
      </Grid>
    </>
  )

  const sortUrls = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
        <Typography variant="h3" sx={h3TypographySX}>
          <Box
            component="span"
            sx={{ color: isDarkModeEnabled ? theme.palette.warning.dark : theme.palette.warning.light }}
          >
            Sort{" "}
          </Box>
          and Stay Organized with Categories and Tags
        </Typography>
        <Typography variant="h5" sx={descriptionSx}>
          Categories and Tags help you stay organized and find what you are looking for as your collection of links
          grows. Nest Categories and Tags inside{" "}
          <Link href="https://www.linkidex.com/blog/posts/introducing-folders/">folders</Link> as you see fit.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            width: { xs: "70%", md: "100%" },
            ...imageSx,
          }}
          alt="Linkidex Sort Links"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexSortLink${LightOrDark()}.png`}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Box>
          <Typography variant="h3" sx={{ ...h3TypographySX }}>
            <Box
              component="span"
              sx={{ color: isDarkModeEnabled ? theme.palette.warning.dark : theme.palette.warning.light }}
            >
              Sort{" "}
            </Box>
            and Stay Organized with Categories and Tags
          </Typography>

          <Typography variant="h5" sx={descriptionSx}>
            Categories and Tags help you stay organized and find what you are looking for as your collection of links
            grows. Nest Categories and Tags inside{" "}
            <Link href="https://www.linkidex.com/blog/posts/introducing-folders/">folders</Link> as you see fit.
          </Typography>
        </Box>
      </Grid>
    </>
  )

  const searchUrls = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Typography variant="h3" sx={h3TypographySX}>
            <Box
              component="span"
              sx={{ color: isDarkModeEnabled ? theme.palette.info.dark : theme.palette.info.light }}
            >
              Search{" "}
            </Box>
            and Find What You Need in an Instant
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            Search across URLs, titles, categories, and tags all at once. Just start typing and Linkidex does the rest.
            Scope your search to specific categories or tags to suit your needs.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            width: { xs: "70%", md: "90%" },
            ...imageSx,
          }}
          alt="Linkidex Links Page"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexSearchLink${LightOrDark()}.png`}
        />
      </Grid>
    </>
  )

  const shareUrls = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
        <Typography variant="h3" sx={h3TypographySX}>
          <Box
            component="span"
            sx={{ color: isDarkModeEnabled ? theme.palette.error.dark : theme.palette.error.light }}
          >
            Share{" "}
          </Box>
          and Collaborate
        </Typography>
        <Typography variant="h5" sx={descriptionSx}>
          Create <Link href="https://www.linkidex.com/blog/posts/introducing-organizations/">Organizations</Link> within
          Linkidex to share all of your team's tools, documentation, wikis and more. Keep everyone on the same page with
          a single source of truth documenting where to find your team's resources.
        </Typography>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            width: { xs: "70%", md: "90%" },
            ...imageSx,
          }}
          alt="Linkidex Manage Org Members"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexShareLink${LightOrDark()}.png`}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Box>
          <Typography variant="h3" sx={h3TypographySX}>
            <Box
              component="span"
              sx={{ color: isDarkModeEnabled ? theme.palette.error.dark : theme.palette.error.light }}
            >
              Share{" "}
            </Box>
            and Collaborate
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            Create <Link href="https://www.linkidex.com/blog/posts/introducing-organizations/">Organizations</Link>{" "}
            within Linkidex to share all of your team's tools, documentation, wikis and more. Keep everyone on the same
            page with a single source of truth documenting where to find your team's resources.
          </Typography>
        </Box>
      </Grid>
    </>
  )

  // const TagAndCategorize = () => (
  //   <>
  //     <Grid item xs={12} md={6}>
  //       <Typography variant="h4" sx={{ pb: 1, pt: { xs: 4, md: 0 } }}>
  //         Tag and categorize the links you care about.
  //       </Typography>
  //       <Typography>
  //         Create categories such as "recipes" and tags such as "Spicy Food" to
  //         keep your bookmarks organized however you see fit. Use folders to keep
  //         your categories and tags manageable as they grow.
  //       </Typography>
  //     </Grid>
  //     <Grid item xs={12} md={6}>
  //       {/* Links page */}
  //       <Card
  //         sx={{
  //           p: 1,
  //           width: "auto",
  //           boxShadow: 0,
  //           border: 1,
  //           borderColor: "divider",
  //         }}
  //       >
  //         <img
  //           style={{ width: "100%" }}
  //           alt=""
  //           src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexHome2${LightOrDark()}.png`}
  //         />
  //       </Card>
  //     </Grid>
  //   </>
  // )

  // const EffortlesslySearch = () => (
  //   <>
  //     <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
  //       <Typography variant="h4" sx={h3TypographySX}>
  //         Effortlessly search your links by title, category, or tag.
  //       </Typography>
  //       <Typography>
  //         Just start typing what you are looking for and Linkidex will search
  //         your categories, tags, links, and urls all at once! You can also
  //         explicitly search or browse select categories and tags.
  //       </Typography>
  //     </Grid>

  //     <Grid item xs={12} md={6}>
  //       {/* Links page searching 'spicy food' */}
  //       <Card
  //         sx={{
  //           p: 1,
  //           width: "auto",
  //           boxShadow: 0,
  //           border: 1,
  //           borderColor: "divider",
  //         }}
  //       >
  //         <img
  //           style={{ width: "100%" }}
  //           alt=""
  //           src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexSearchLinks2${LightOrDark()}.png`}
  //         />
  //       </Card>
  //     </Grid>

  //     <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "block" } }}>
  //       <Typography variant="h4" sx={h3TypographySX}>
  //         Effortlessly search your links by title, category, or tag.
  //       </Typography>
  //       <Typography>
  //         Just start typing what you are looking for and Linkidex will search
  //         your categories, tags, links, and urls all at once! You can also
  //         explicitly search or browse select categories and tags.
  //       </Typography>
  //     </Grid>
  //   </>
  // )

  const WorksAcrossAny = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
        <Box sx={gradientBoxSx}>
          <Typography variant="h4" sx={h3TypographySX}>
            Use on
            <Box component="span" sx={{}}>
              {" "}
              Any Browser, Any Device
            </Box>
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            Linkidex is a progressive web application. It works across any browser, any device, is mobile friendly, and
            can also be <Link href="https://www.linkidex.com/blog/posts/how-to-download-linkidex/">downloaded</Link> to
            your phone and function like a native phone app.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            margin: "auto",
            width: { xs: "70%", md: "50%" },
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.divider,
          }}
          alt="Linkidex Mobile View"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexSearchLinksMobile${LightOrDark()}.png`}
        />
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}>
        <Box sx={gradientBoxSx}>
          <Typography variant="h4" sx={h3TypographySX}>
            Use on
            <Box component="span" sx={{}}>
              {" "}
              Any Browser, Any Device
            </Box>{" "}
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            Linkidex is a progressive web application. It works across any browser, any device, is mobile friendly, and
            can also be <Link href="https://www.linkidex.com/blog/posts/how-to-download-linkidex/">downloaded</Link> to
            your phone and function like a native web app.
          </Typography>
        </Box>
      </Grid>
    </>
  )

  const ToggleBetweenLight = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "flex" }, alignItems: "center" }}>
        <Box sx={gradientBoxSx}>
          <Typography variant="h4" sx={h3TypographySX} textAlign="center">
            Toggle Between
            <Box component="span" sx={{}}>
              {" "}
              Light{" "}
            </Box>
            and
            <Box component="span" sx={{}}>
              {" "}
              Dark{" "}
            </Box>
            Mode.
          </Typography>

          <LightDarkSlider />
        </Box>
      </Grid>

      <Grid item xs={12} md={3}>
        {/* Links page searching 'spicy food' */}
        <Box
          component="img"
          sx={{
            display: { xs: "none", md: "block" },
            margin: "auto",
            width: { xs: "70%", md: "100%" },
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.divider,
          }}
          alt="Linkidex Home Mobile Light"
          src="https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexHomeMobileLight.png"
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          component="img"
          sx={{
            display: { xs: "none", md: "block" },
            margin: "auto",
            width: { xs: "70%", md: "100%" },
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.divider,
          }}
          alt="Linkidex Home Mobile Dark"
          src="https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexHomeMobileDark.png"
        />
      </Grid>
    </>
  )

  const ImportExport = () => (
    <>
      <Grid item xs={12} md={6} sx={{ display: "flex" }}>
        <Box sx={gradientBoxSx}>
          <Typography variant="h4" sx={h3TypographySX}>
            <Box component="span" sx={{}}>
              Import / Export{" "}
            </Box>
            Anytime
          </Typography>
          <Typography variant="h5" sx={descriptionSx}>
            Linkidex can <Link href="https://www.linkidex.com/blog/posts/how-to-import-bookmarks/">import</Link> all of
            your browser bookmarks, including folders, categories, and tags. Linkidex also lets you{" "}
            <Link href="https://www.linkidex.com/blog/posts/how-to-export-bookmarks/">export</Link> your bookmarks at
            any time, so you can easily upload your Linkidex bookmarks to your favorite browser or another tool.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: "flex", alignItems: "center" }}>
        <Box
          component="img"
          sx={{
            width: { xs: "90%", md: "100%" },
            ...imageSx,
          }}
          alt="Linkidex Import / Export"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/LinkidexImportBookmarks${LightOrDark()}.png`}
        />
      </Grid>
    </>
  )

  return (
    <Box sx={{ margin: "auto", maxWidth: "1200px" }}>
      <Box sx={{ justifyContent: "center", pt: 2, display: { xs: "flex", sm: "none" } }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            window.open(`${process.env.PUBLIC_URL}/blog/`, "_blank")
          }}
          sx={{ mx: 1 }}
        >
          <NewspaperIcon sx={{ fontSize: "20px", mr: 1 }} />
          Blog
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/pricing")
          }}
          sx={{ mx: 1 }}
        >
          <AttachMoneyIcon sx={{ fontSize: "20px", mr: 1 }} />
          Pricing
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            window.open(`${process.env.PUBLIC_URL}/blog/categories/release-notes/`, "_blank")
          }}
          sx={{ mx: 1 }}
        >
          <CampaignIcon sx={{ fontSize: "20px", mr: 1 }} />
          Updates
        </Button>
      </Box>
      <Typography textAlign="center" variant="h1" sx={{ margin: "auto", maxWidth: "650px", pt: { xs: 2, md: 10 } }}>
        Like bookmarks, but way better
      </Typography>
      <Typography textAlign="center" variant="h5" sx={{ py: { xs: 2, md: 4 } }}>
        Effortlessly save, sort, search, and share URLs you care about.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 4 }}>
        <Button variant="contained" color="success" onClick={() => openSignUpModal()}>
          Get Started For Free <ArrowForwardIcon sx={{ ml: 1, fontSize: "25px" }} />
        </Button>
      </Box>

      <Box sx={{ mx: 2, mt: 2 }}>
        <Grid container rowSpacing={{ xs: 8, md: 17 }} columnSpacing={6}>
          {saveUrls()}
          {sortUrls()}
          {searchUrls()}
          {shareUrls()}
          <Grid item xs={12}>
            <Typography textAlign="center" variant="h2" sx={{ fontWeight: "bold" }}>
              What else can Linkidex do?
            </Typography>
          </Grid>
          {ImportExport()}
          {WorksAcrossAny()}
          {ToggleBetweenLight()}
        </Grid>
      </Box>

      <Box sx={{ mx: 2, mt: 2 }}>
        <Typography textAlign="center" variant="h2" sx={{ pb: 2, pt: { xs: 4, md: 12 } }}>
          Use Linkidex for free as long as you like.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="success" onClick={() => openSignUpModal()}>
            Create Account
          </Button>
        </Box>
        <Typography textAlign="center" sx={{ mt: 4, pt: 2, maxWidth: "750px", margin: "auto" }}>
          We don't sell your data and we don't show ads. Linkidex is exclusively supported by users like you. You can
          see our simple, no nonsense pricing <Link href={`${process.env.PUBLIC_URL}/pricing`}>here</Link>.
        </Typography>
      </Box>

      <Box sx={{ pb: 10 }}>{/* padding between create account and bottom links */}</Box>
    </Box>
  )
}
