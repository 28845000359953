import { useIsLoggedIn } from "Utils/Utils"
import { Navigate, useLocation } from "react-router-dom"
import { Outlet } from "react-router"

export const RecoveryRoutes = () => {
  const isLoggedIn = useIsLoggedIn()

  const location = useLocation()

  if (!isLoggedIn) {
    return <Outlet />
  } else {
    return <Navigate to="/" replace state={{ from: location }} />
  }
}
