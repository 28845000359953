import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface SelectedTagsInterface {
  [key: number]: boolean
}

interface SelectedTagsContextInterface {
  selectedTagsState: SelectedTagsInterface
  setSelectedTagsState: React.Dispatch<React.SetStateAction<SelectedTagsInterface>>
}

const defaultState = {}

export const SelectedTagsContext = createContext<SelectedTagsContextInterface>({
  selectedTagsState: defaultState,
  setSelectedTagsState: () => {},
})

export const SelectedTagsContextProvider = (props: { children: React.ReactNode }) => {
  const [selectedTagsState, setSelectedTagsStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["selectedTagsStateChrome"], (result) => {
        if (result.selectedTagsStateChrome) {
          setSelectedTagsStatePrivate(result.selectedTagsStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ selectedTagsStateChrome: selectedTagsState })
    }
  }, [selectedTagsState])

  // Update state
  const setSelectedTagsState = useCallback((newState) => {
    setSelectedTagsStatePrivate(newState)
  }, [])

  return (
    <SelectedTagsContext.Provider
      value={{
        selectedTagsState,
        setSelectedTagsState,
      }}
    >
      {props.children}
    </SelectedTagsContext.Provider>
  )
}
