import { useContext, useRef } from "react"
import { ApiUtils } from "Utils/ApiUtils"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"
import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { debounceFunction } from "Utils/Utils"
import { DEBOUNCE_TIME } from "constants/Global"

interface ExportBookmarksRowInterface {
  textStyle: SxProps
}

export const ExportBookmarksRow = ({ textStyle }: ExportBookmarksRowInterface) => {
  const { data } = useQuery(getActiveUserQuery)
  const { setSnackBarState } = useContext(SnackBarContext)
  const timeout = useRef<any>()

  const handleExport = () => {
    if (data?.activeUser?.id) {
      ApiUtils.exportBookmarks(data?.activeUser?.id).then(
        (res: object) => {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Your bookmarks are on the way! Please give us up to 10 minutes to generate your email",
          })
        },
        (res: any) => {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.error || "Something went wrong! Please try again, or reach out to help@linkidex.com",
          })
        }
      )
    }
  }

  const debouncedHandleExport = () => {
    debounceFunction(timeout, handleExport, DEBOUNCE_TIME)
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          Export Bookmarks
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          Receive a ".html" file via email containing all of your links.
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          variant="outlined"
          component="label"
          aria-label="export-bookmarks"
          onClick={debouncedHandleExport}
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          Export
        </Button>
      </Grid>
    </>
  )
}
