import { useState, useContext, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"

// MUI Components
import Checkbox from "@mui/material/Checkbox"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemButton from "@mui/material/ListItemButton"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// TYPES
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import {
  OrganizationRoleInterface,
  OrganizationUserRoleInterface,
} from "interfaces/Organization"

// Utils
import {
  getOrganizationRolesQuery,
  createOrganizationUserRoleMutation,
  deleteOrganizationUserRoleMutation,
  writeOrganizationUserRoleQuery,
  getUserOrganizationQuery,
} from "queries/queries"
import { debounceFunction } from "Utils/Utils"

export const ManageUserOrganizationPermissionsModal = () => {
  const timeout = useRef<any>()

  const [errorText, setErrorText] = useState<string>(" ")

  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)

  const userOrganization = useQuery(getUserOrganizationQuery, {
    variables: {
      id: modalState.data.userOrganizationId,
    },
  })

  const organizationRoles = useQuery(getOrganizationRolesQuery, {
    variables: {
      organizationId: modalState.data.organizationId,
    },
  })

  const [addOrganizationUserRole] = useMutation(
    createOrganizationUserRoleMutation,
    {
      update(cache, { data: { createOrganizationUserRole } }) {
        cache.modify({
          fields: {
            organizationUserRoles(existingOrganizationUserRoles = []) {
              const newOrganizationUserRoleRef = cache.writeFragment({
                data: createOrganizationUserRole,
                fragment: writeOrganizationUserRoleQuery,
              })
              return [
                ...existingOrganizationUserRoles,
                newOrganizationUserRoleRef,
              ]
            },
          },
        })
      },
    }
  )

  const [deleteOrganizationUserRole] = useMutation(
    deleteOrganizationUserRoleMutation,
    {
      update(cache, { data: { deleteOrganizationUserRole } }) {
        cache.evict({ id: cache.identify(deleteOrganizationUserRole) })
      },
    }
  )

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const isChecked = (id: string) => {
    return !!userOrganization.data?.userOrganization?.organizationUserRoles.find(
      (organizationUserRole: OrganizationUserRoleInterface) =>
        organizationUserRole.organizationRoleId === id
    )
  }

  const getOrganizationUserRoleIdByOrganizationRoleId = (id: string) => {
    // returns OrganizationUserRole.id for OrganizationUserRole with given organizationRoleId
    return userOrganization.data?.userOrganization?.organizationUserRoles.find(
      (organizationUserRole: OrganizationUserRoleInterface) =>
        organizationUserRole.organizationRoleId === id
    ).id
  }

  const debouncedToggleRoleForUser = (organizationRoleId: string) => {
    debounceFunction(
      timeout,
      () => toggleRoleForUser(organizationRoleId),
      DEBOUNCE_TIME
    )
  }

  const toggleRoleForUser = (organizationRoleId: string) => {
    setErrorText("")

    if (isChecked(organizationRoleId)) {
      deleteOrganizationUserRole({
        variables: {
          id: getOrganizationUserRoleIdByOrganizationRoleId(organizationRoleId),
        },
      }).then(
        (res: any) => {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Role successfully removed from user.",
          })
        },
        (res: any) => {
          setErrorText("")
          if (res && res.message) {
            setErrorText(res.message)
          } else {
            setErrorText("Something went wrong, please try again.")
          }
        }
      )
    } else {
      addOrganizationUserRole({
        variables: {
          userId: userOrganization.data?.userOrganization?.userId,
          organizationRoleId: organizationRoleId,
        },
        refetchQueries: [
          {
            query: getUserOrganizationQuery,
            variables: { id: modalState.data.userOrganizationId },
          },
        ],
      }).then(
        (res: any) => {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Role successfully added to user.",
          })
        },
        (res: any) => {
          setErrorText("")
          if (res && res.message) {
            setErrorText(res.message)
          } else {
            setErrorText("Something went wrong, please try again.")
          }
        }
      )
    }
  }

  const renderOrganizationRoles = () => {
    return (
      <>
        {organizationRoles?.data?.organizationRoles?.map(
          (organizationRole: OrganizationRoleInterface) => (
            <ListItem disablePadding key={organizationRole.id}>
              <ListItemButton
                id="toogle-org-role"
                aria-label="toogle-org-role"
                onClick={(e: any) => {
                  debouncedToggleRoleForUser(organizationRole.id)
                }}
              >
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <Checkbox
                    checked={isChecked(organizationRole.id)}
                    disableRipple
                    tabIndex={-1}
                  />
                </ListItemIcon>
                <Typography>{organizationRole.userFacingName}</Typography>
              </ListItemButton>
            </ListItem>
          )
        )}
      </>
    )
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2, wordBreak: "break-word" }}
      >
        Manage Roles For {userOrganization.data?.userOrganization?.email}
      </Typography>
      <Typography>
        Click individual roles below to add or remove them from the given user.
      </Typography>
      <List>{renderOrganizationRoles()}</List>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={!!errorText}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button color="primary" variant="outlined" onClick={closeModal}>
                Done
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
