import { useState, useEffect } from "react"
import { CURRENT_VERSION_KEY } from "constants/Global"
import { useIsExtension } from "Utils/Utils"

// MUI
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Collapse from "@mui/material/Collapse"

export const UpdateAppAlertBar = () => {
  const isExtension = useIsExtension()
  const [isOpen, setIsOpen] = useState(false)
  const [newAppVersion, setNewAppVersion] = useState<null | string>(null)
  const [currentClientVersion, setCurrentClientVersion] = useState<null | string>(null)

  // get current client version from cookie
  useEffect(() => {
    var match = document.cookie.match(new RegExp("(^| )" + "linkidex_client_version" + "=([^;]+)"))
    if (match) {
      setCurrentClientVersion(match[2])
    }
  }, [])

  // get most recent client version from most recent response header
  useEffect(() => {
    const localStorageSetHandler = () => {
      const item = localStorage.getItem(CURRENT_VERSION_KEY)
      if (item) {
        setNewAppVersion(item)
      }
    }
    // `itemInserted` events are being fired by our service worker when they read headers.
    document.addEventListener("itemInserted", localStorageSetHandler, false)
    return () => {
      window.removeEventListener("itemInserted", localStorageSetHandler)
    }
  }, [])

  useEffect(() => {
    if (window.location.host === "localhost:3000") {
      return
    }
    if (isExtension) {
      return
    }
    if (currentClientVersion && newAppVersion && newAppVersion !== "null") {
      if (currentClientVersion !== newAppVersion) {
        setIsOpen(true)
      } else {
        setIsOpen(false)
      }
    } else {
      setIsOpen(false)
    }
  }, [newAppVersion, currentClientVersion, isExtension])

  const refreshPage = () => {
    window.location.reload()
  }

  return (
    <>
      <Collapse in={isOpen} sx={{ px: { xs: 2, md: 6 } }}>
        <Alert sx={{ mt: 1 }} variant="outlined" severity="error">
          <Typography>
            App is out of date, which may cause issues! Please reload.
            <Button
              sx={{ ml: 1 }}
              size="small"
              variant="contained"
              color="error"
              onClick={refreshPage}
              aria-label="Reload out of date application."
            >
              Reload
            </Button>
          </Typography>
        </Alert>
      </Collapse>
    </>
  )
}
