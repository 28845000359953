import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface IsStrictSearchContextInterface {
  isStrictSearch: boolean
  setIsStrictSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const IsStrictSearchContext = createContext<IsStrictSearchContextInterface>({
  isStrictSearch: defaultState,
  setIsStrictSearch: () => {},
})

export const IsStrictSearchContextProvider = (props: { children: React.ReactNode }) => {
  const [isStrictSearch, setIsStrictSearchPrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["isStrictSearchChrome"], (result) => {
        if (result.isStrictSearchChrome) {
          setIsStrictSearchPrivate(result.isStrictSearchChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ isStrictSearchChrome: isStrictSearch })
    }
  }, [isStrictSearch])

  // Update state
  const setIsStrictSearch = useCallback((newState) => {
    setIsStrictSearchPrivate(newState)
  }, [])

  return (
    <IsStrictSearchContext.Provider
      value={{
        isStrictSearch,
        setIsStrictSearch,
      }}
    >
      {props.children}
    </IsStrictSearchContext.Provider>
  )
}
