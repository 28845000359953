import { useContext, useEffect } from "react"
import { ListChildComponentProps } from "react-window"
import { useMutation } from "@apollo/client"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { LinkInterface } from "interfaces/Link"
import Fuse from "fuse.js"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Components
import { SearchRowDesktop } from "./SearchRowDesktop"
import { SearchRowMobile } from "./SearchRowMobile"

// Utils
import { useIsMobile, useIsExtension } from "Utils/Utils"
import { viewLinkMutation } from "queries/queries"

export const SearchRow = (
  props: ListChildComponentProps<{
    filteredResults: Fuse.FuseResult<LinkInterface>[]
  }>
) => {
  const { index, style, data } = props
  const isMobile = useIsMobile()
  const isExtension = useIsExtension()
  const [viewLink] = useMutation(viewLinkMutation)

  const link = data.filteredResults[index].item
  // const { url, title, description, id, category, tags, organization } = data.filteredResults[index].item
  const { setSnackBarState } = useContext(SnackBarContext)
  const { modalState, setModalState } = useContext(ModalContext)

  useEffect(() => {
    // this useEffect updates the edit modal's state when a given link is edited but the edit link modal remains open,
    // such as when a user adds or removes tags
    if (modalState.isOpen && modalState.modalType === MODAL_TYPES.EDIT_LINK && modalState.data.id === link.id) {
      setModalState({
        isOpen: true,
        modalType: MODAL_TYPES.EDIT_LINK,
        data: {
          id: link.id,
          url: link.url,
          title: link.title,
          description: link.description,
          category: link.category,
          tags: link.tags,
          organization: link.organization,
        },
      })
    }
  }, [link, modalState?.data?.id, modalState.isOpen, modalState.modalType, setModalState])

  const openLink = (event: any) => {
    if (link.url.includes("http")) {
      window.open(`${link.url}`, "_blank", "noreferrer")
    } else {
      window.open(`http://${link.url}`, "_blank", "noreferrer")
    }
    viewLink({
      variables: {
        id: link.id,
      },
    })
  }

  const copyLink = () => {
    setSnackBarState({
      isOpen: true,
      snackBarType: SNACK_BAR_TYPES.SUCCESS,
      message: `${link.url} copied to clipboard!`,
    })
    navigator.clipboard.writeText(link.url)
  }

  const editLink = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.EDIT_LINK,
      data: {
        id: link.id,
        url: link.url,
        title: link.title,
        description: link.description,
        category: link.category,
        tags: link.tags,
        organization: link.organization,
      },
    })
  }

  const deleteLink = () => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.DELETE_LINK,
      data: {
        id: link.id,
        url: link.url,
        title: link.title,
        description: link.description,
        organization: link.organization,
      },
    })
  }

  const renderRow = () => {
    if (isMobile || isExtension) {
      return (
        <SearchRowMobile
          style={style}
          index={index}
          linkId={link.id}
          linkTitle={link.title}
          linkDescription={link.description}
          linkCategory={link.category}
          linkTags={link.tags}
          linkUserLinkFavorites={link.userLinkFavorites}
          copyLink={copyLink}
          openLink={openLink}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      )
    } else {
      return (
        <SearchRowDesktop
          style={style}
          index={index}
          linkId={link.id}
          linkTitle={link.title}
          linkDescription={link.description}
          linkCategory={link.category}
          linkTags={link.tags}
          linkUserLinkFavorites={link.userLinkFavorites}
          copyLink={copyLink}
          openLink={openLink}
          editLink={editLink}
          deleteLink={deleteLink}
        />
      )
    }
  }

  return <>{renderRow()}</>
}
