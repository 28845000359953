import { useState, useContext, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/client"
import {
  getFoldersQuery,
  getCategoriesQuery,
  createOrUpdateCategoryFolderItemMutation,
  deleteCategoryFolderItemMutation,
  updateFolderMutation,
  writeFolderQuery,
} from "queries/queries"
import { NodeModel } from "@minoru/react-dnd-treeview"
import { PinInterface } from "interfaces/Pin"

import { SnackBarContext } from "contexts/SnackBarContext"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// MUI
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"

// Components
import { CategoryIcon } from "./CategoryIcon"
import { SideDrawerTreeView } from "../SideDrawerTreeView"
import { UserCategoryDropdown } from "./UserCategoryDropdown"
// Utils
import { buildFolderData, buildCategoryData } from "Utils/Utils"

// Types
import { FOLDER, CATEGORY, TAG } from "constants/Global"

interface UserCategoriesProps {
  isNotMobile: boolean
  isUserOnLinksPage: boolean
  toggleItem: (id: number) => void
  isItemChecked: (id: number) => boolean | undefined
  organizationId?: string
  isDisabled?: boolean
}

export const UserCategories = (props: UserCategoriesProps) => {
  const { isNotMobile, isUserOnLinksPage, toggleItem, isItemChecked, organizationId, isDisabled } = props

  const { setSnackBarState } = useContext(SnackBarContext)

  const folderQueryData = useQuery(getFoldersQuery)
  const categoryQueryData = useQuery(getCategoriesQuery)
  const [createOrUpdateFolderItem] = useMutation(createOrUpdateCategoryFolderItemMutation)
  const [deleteFolderItem] = useMutation(deleteCategoryFolderItemMutation)
  const [editFolder] = useMutation(updateFolderMutation, {
    update(cache, { data: { updateFolder } }) {
      cache.modify({
        id: cache.identify(updateFolder),
        fields: {
          links(existingFolders = []) {
            const newFolderRef = cache.writeFragment({
              data: updateFolder,
              fragment: writeFolderQuery,
            })
            return [...existingFolders, newFolderRef]
          },
        },
      })
    },
  })

  const handleCreateOrUpdateFolderItem = (itemId: number, itemType: string, folderId: number) => {
    createOrUpdateFolderItem({
      variables: { itemId, itemType, folderId },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Category Moved`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  const handleDeleteFolderItem = (itemId: number, itemType: string) => {
    deleteFolderItem({
      variables: { itemId, itemType },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Folder Deleted`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  const handleUpdateFolder = (id: number, parentFolderId?: number, title?: string) => {
    editFolder({
      variables: { id, parentFolderId, title },
      refetchQueries: [getFoldersQuery],
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Folder Moved`,
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: res.toString(),
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Error - something went wrong, please try again.",
          })
        }
      }
    )
  }

  // TODO: put this in a use effect so it doesn't needlessly run
  const buildTreeData = (): NodeModel<{
    pin: PinInterface
    contentType: typeof FOLDER | typeof CATEGORY | typeof TAG
  }>[] => {
    const formattedFolderData = folderQueryData.data?.folders
      ? buildFolderData(folderQueryData.data.folders, CATEGORY, isNotMobile, organizationId)
      : []

    const formattedCategoryData = categoryQueryData.data?.categories
      ? buildCategoryData(
          categoryQueryData.data.categories,
          isNotMobile,
          organizationId,
          isItemChecked,
          isUserOnLinksPage,
          toggleItem
        )
      : []
    // @ts-ignore
    return formattedFolderData.concat(formattedCategoryData)
  }

  const chromeStorageKey = organizationId ? `categories-dropdown-${organizationId}` : `categories-dropdown-personal`
  const [isTreeOpen, setIsTreeOpen] = useState<boolean>(false)

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get([chromeStorageKey], (result) => {
        const openStatus = result[chromeStorageKey]?.openStatus
        if (typeof openStatus === "boolean") {
          setIsTreeOpen(openStatus) // Update state with fetched value
        }
      })
    }
  }, [chromeStorageKey])

  const toggleCategoryDropdown = () => {
    const newOpenStatus = !isTreeOpen

    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ [chromeStorageKey]: { openStatus: newOpenStatus } }, () => {})
    }

    setIsTreeOpen(newOpenStatus) // Update state
  }

  const determineItemTypeFromDragSourceId = (dragSourceId: string) => {
    return dragSourceId.split("-")[0]
  }

  const determineItemIdFromDragSourceId = (dragSourceId: string) => {
    return parseInt(dragSourceId.split("-")[1]) || 0
  }

  const handleDrop = (newTree: any, { dragSourceId, dropTargetId, dragSource, dropTarget }: any) => {
    // TODO: throw front end error and don't do anything if itemId or itemType or folderId don't exist
    // instead of returning empty string or 0. Note: must handle valid use of setting folder_id to 0
    const itemType = determineItemTypeFromDragSourceId(dragSourceId)
    const itemId = determineItemIdFromDragSourceId(dragSourceId)

    if (itemType === CATEGORY) {
      if (dropTargetId) {
        const folderId = determineItemIdFromDragSourceId(dropTargetId)
        handleCreateOrUpdateFolderItem(itemId, itemType, folderId)
      } else if (dropTargetId === 0) {
        handleDeleteFolderItem(itemId, itemType)
      }
    } else if (itemType === FOLDER) {
      const folderId = determineItemIdFromDragSourceId(dragSourceId)
      const parentFolderId = dropTargetId ? determineItemIdFromDragSourceId(dropTargetId) : 0
      handleUpdateFolder(folderId, parentFolderId)
    }
  }

  const LABEL_BY = "link-side-drawer-categories"

  const ariaLabel = () => {
    return `toggle-category-list. List currently ${isTreeOpen ? "open" : "closed"}`
  }

  const determineTimeout = () => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      return {
        appear: 100,
        enter: 100,
        exit: 100,
      }
    }
    return "auto"
  }

  return (
    <>
      <ListItem
        key={`${LABEL_BY}`}
        id={`${LABEL_BY}`}
        disablePadding
        aria-label={ariaLabel()}
        aria-pressed={isTreeOpen}
        dense={isNotMobile}
      >
        <CategoryIcon aria-labelledby={`${LABEL_BY}`} toggleCategoryDropdown={toggleCategoryDropdown} />

        <ListItemButton onClick={toggleCategoryDropdown} sx={{ pr: 1 }}>
          <ListItemText
            primary="Categories"
            aria-labelledby={`${LABEL_BY}`}
            primaryTypographyProps={{
              "aria-labelledby": `${LABEL_BY}`,
              variant: "h6",
            }}
          />
          <UserCategoryDropdown organizationId={organizationId} />

          {/* {isTreeOpen ? <ExpandMore /> : <ExpandLess />} */}
        </ListItemButton>
      </ListItem>
      <Collapse in={isTreeOpen} timeout={determineTimeout()} unmountOnExit>
        <SideDrawerTreeView treeData={buildTreeData()} handleDrop={handleDrop} isDisabled={isDisabled} />
      </Collapse>
    </>
  )
}
