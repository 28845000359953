import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface PersonalLinksSelectedContextInterface {
  personalLinksSelectedState: boolean
  setPersonalLinksSelectedState: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultState = false

export const PersonalLinksSelectedContext = createContext<PersonalLinksSelectedContextInterface>({
  personalLinksSelectedState: defaultState,
  setPersonalLinksSelectedState: () => {},
})

export const PersonalLinksSelectedContextProvider = (props: { children: React.ReactNode }) => {
  const [personalLinksSelectedState, setPersonalLinksSelectedStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["personalLinksSelectedStateChrome"], (result) => {
        if (result.personalLinksSelectedStateChrome) {
          setPersonalLinksSelectedStatePrivate(result.personalLinksSelectedStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ personalLinksSelectedStateChrome: personalLinksSelectedState })
    }
  }, [personalLinksSelectedState])

  // Update state
  const setPersonalLinksSelectedState = useCallback((newState) => {
    setPersonalLinksSelectedStatePrivate(newState)
  }, [])

  return (
    <PersonalLinksSelectedContext.Provider
      value={{
        personalLinksSelectedState,
        setPersonalLinksSelectedState,
      }}
    >
      {props.children}
    </PersonalLinksSelectedContext.Provider>
  )
}
