import * as React from "react"
import { createContext, useState, useEffect, useCallback } from "react"

interface SelectedOrganizationsInterface {
  [key: string]: boolean
}

interface SelectedOrganizationsContextInterface {
  selectedOrganizationsState: SelectedOrganizationsInterface
  setSelectedOrganizationsState: React.Dispatch<React.SetStateAction<SelectedOrganizationsInterface>>
}

const defaultState = {}

export const SelectedOrganizationsContext = createContext<SelectedOrganizationsContextInterface>({
  selectedOrganizationsState: defaultState,
  setSelectedOrganizationsState: () => {},
})

export const SelectedOrganizationsContextProvider = (props: { children: React.ReactNode }) => {
  const [selectedOrganizationsState, setSelectedOrganizationsStatePrivate] = useState(defaultState)

  // Load state from chrome.storage.local on mount
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get(["selectedOrganizationsStateChrome"], (result) => {
        if (result.selectedOrganizationsStateChrome) {
          setSelectedOrganizationsStatePrivate(result.selectedOrganizationsStateChrome) // Initialize context state
        }
      })
    }
  }, [])

  // Save state to chrome.storage.local on state change
  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ selectedOrganizationsStateChrome: selectedOrganizationsState })
    }
  }, [selectedOrganizationsState])

  // Update state
  const setSelectedOrganizationsState = useCallback((newState) => {
    setSelectedOrganizationsStatePrivate(newState)
  }, [])

  return (
    <SelectedOrganizationsContext.Provider
      value={{
        selectedOrganizationsState,
        setSelectedOrganizationsState,
      }}
    >
      {props.children}
    </SelectedOrganizationsContext.Provider>
  )
}
