import * as React from "react"
import { useState, useEffect, useMemo, useRef, useContext } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { VariableSizeList, ListChildComponentProps } from "react-window"
import { SnackBarContext } from "contexts/SnackBarContext"

// MUI
import { useTheme, styled } from "@mui/material/styles"
import Autocomplete, { autocompleteClasses, AutocompleteChangeReason } from "@mui/material/Autocomplete"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import TextField from "@mui/material/TextField"
import useMediaQuery from "@mui/material/useMediaQuery"
import ListSubheader from "@mui/material/ListSubheader"
import Popper from "@mui/material/Popper"
import Dialog from "@mui/material/Dialog"
import DialogContent from "@mui/material/DialogContent"
import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome"

// Components
import { CreateTagModal } from "components/Modal/CreateTagModal"

// Utils
import { getTagsQuery, createTagMutation, writeTagQuery } from "queries/queries"
import { sortByText, debounceFunction } from "Utils/Utils"

// Types
import { TagInterface } from "interfaces/Tags"
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  }

  if (dataSet.hasOwnProperty("group")) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props
  const itemData: React.ReactChild[] = []
  ;(children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  })
  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty("group")) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
})

interface LinkTagFormProps {
  organizationId: string
  selectedTags: TagInterface[]
  setSelectedTags: (tags: TagInterface[]) => void
  suggestions: string[]
}
export const LinkTagForm = (props: LinkTagFormProps) => {
  const timeout = useRef<any>()
  const { organizationId, selectedTags, setSelectedTags, suggestions } = props
  const { data } = useQuery(getTagsQuery)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newTag, setNewTag] = useState<string | null>(null)
  const [sortedTags, setSortedTags] = useState<TagInterface[]>([])
  const { setSnackBarState } = useContext(SnackBarContext)

  const [addTag] = useMutation(createTagMutation, {
    update(cache, { data: { createTag } }) {
      cache.modify({
        fields: {
          tags(existingTags = []) {
            const newTagRef = cache.writeFragment({
              data: createTag,
              fragment: writeTagQuery,
            })
            return [...existingTags, newTagRef]
          },
        },
      })
    },
  })

  useEffect(() => {
    if (data && data.tags) {
      const filteredTags = data.tags.filter((tag: TagInterface) => {
        if (organizationId === "0") {
          return !tag.organization?.id
        } else {
          return tag.organization?.id === organizationId
        }
      })

      const sorted = filteredTags.sort(function (a: TagInterface, b: TagInterface) {
        return sortByText(a.title, b.title)
      })
      setSortedTags(sorted)
    }
  }, [data, organizationId])

  const allTags = useMemo(() => (sortedTags ? sortedTags : []), [sortedTags])
  // wrapped in useMemo as we are watching allTags in a useEffect, which gets weird with objects

  const createTagCallBack = (newTagResponse: any) => {
    // save ID of tag that was created while 'select category' was open
    setNewTag(newTagResponse.id)
  }

  useEffect(() => {
    // if newTag exists, select our new tag from allTags
    // once allTags has been asynchronously updated
    if (newTag) {
      const tagMatchIndex = allTags.findIndex((tag: any) => {
        return tag.id === newTag
      })

      if (tagMatchIndex >= 0) {
        setSelectedTags([...selectedTags, allTags[tagMatchIndex]])
        setNewTag(null)
      }
    }
  }, [allTags, setSelectedTags, newTag, selectedTags])

  const handleInputChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: TagInterface[],
    reason: AutocompleteChangeReason,
    details?: any
  ) => {
    setSelectedTags(value)
  }

  const createTag = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCreateTag = (title: string, orgId: string | undefined) => {
    if (orgId === "0") {
      orgId = undefined
    }
    addTag({
      variables: {
        title: title,
        description: "",
        organizationId: orgId,
        folderId: null,
      },
    }).then(
      (res: any) => {
        createTagCallBack(res.data.createTag)
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Tag Created!",
        })
      },
      (res: any) => {
        if (res && res.message) {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: res.message,
          })
        } else {
          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Something went wrong, please try again.",
          })
        }
      }
    )
  }

  const buildTagSuggestions = () => {
    return suggestions?.map((suggestion: string, idx: number) => {
      // does this suggestion exist within available tags
      const foundAvailableTag = sortedTags?.find((tag: TagInterface) => tag.title === suggestion)
      const tooltip = foundAvailableTag ? "AI suggestion from your existing tags" : "AI suggests creating this new tag"
      const icon = foundAvailableTag ? undefined : <AutoAwesomeIcon fontSize="small" />

      return (
        <Tooltip key={idx} followCursor title={tooltip}>
          <Chip
            sx={{ mx: 1 }}
            icon={icon}
            label={suggestion}
            variant="outlined"
            color={determineChipColor(suggestion)}
            onClick={() => {
              selectSuggestion(suggestion, foundAvailableTag)
            }}
          />
        </Tooltip>
      )
    })
  }

  const determineChipColor = (suggestion: string) => {
    if (selectedTags && selectedTags.find((tag: TagInterface) => tag.title === suggestion)) {
      return "success"
    }
    return "default"
  }

  const selectSuggestion = (suggestion: string, foundAvailableTag?: TagInterface) => {
    // has this tag already been selected for this link? (Don't create duplicate TagLinks)
    const foundSelectedTag = selectedTags?.find((tag: TagInterface) => tag.title === suggestion)
    if (foundAvailableTag && !foundSelectedTag) {
      setSelectedTags([...selectedTags, foundAvailableTag])
    } else if (!foundAvailableTag && !foundSelectedTag) {
      debouncedHandleCreateTag(suggestion, organizationId)
    }
  }

  const debouncedHandleCreateTag = (title: string, organizationId: string | undefined) => {
    debounceFunction(
      timeout,
      () => {
        handleCreateTag(title, organizationId)
      },
      DEBOUNCE_TIME
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {buildTagSuggestions()}
      </Grid>
      <Grid item xs={12} md={6}>
        <Autocomplete
          limitTags={2}
          multiple
          id="select-tag-auto-complete"
          sx={{ width: "100%", mt: 2 }}
          disableListWrap
          // filterOptions={} // TODO DAVID
          PopperComponent={StyledPopper}
          ListboxComponent={ListboxComponent}
          options={allTags}
          renderInput={(params) => <TextField {...params} label="Available Tags" />}
          renderOption={(props, option: TagInterface) => [props, option.title]}
          getOptionLabel={(option: TagInterface) => option.title}
          renderGroup={(params) => params}
          onChange={handleInputChange}
          value={selectedTags}
        />
      </Grid>

      <Grid item xs={12} md={3}></Grid>
      <Grid item xs={12} md={3}>
        <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <Button sx={{ mt: 3 }} onClick={createTag} variant="outlined">
            New Tag
          </Button>
        </Box>
      </Grid>

      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth={true}>
        <DialogContent>
          <CreateTagModal
            callBack={createTagCallBack}
            isControlledByContext={false}
            handleClose={handleCloseModal}
            defaultOrganizationId={organizationId}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  )
}
