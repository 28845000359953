import { useIsLoggedIn } from "Utils/Utils"
import { SideDrawer } from "components/SideDrawer"

export const SideDrawerWrapper = () => {
  // do not render the sidebar if currentUser does not exist.
  // This makes getCategoriesQuery and getTagsQuery within the sidebar fire
  // after the user logs in.
  const isLoggedIn = useIsLoggedIn()
  if (!isLoggedIn) {
    return <></>
  }

  return (
    <>
      <SideDrawer />
    </>
  )
}
