import { useState } from "react"

// mui
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton } from "@mui/material"

interface LinkDropdownPropsInterface {
  linkTitle: string
  editLink: () => void
  deleteLink: () => void
  copyLink: () => void
  setIsVisible?: (value: boolean) => void
}

export const LinkDropdown = (props: LinkDropdownPropsInterface) => {
  const { linkTitle, editLink, deleteLink, copyLink, setIsVisible } = props
  const [open, setIsOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    if (setIsVisible) {
      setIsVisible(true)
    }
    event.stopPropagation()
    event.preventDefault()
    setIsOpen(true)
  }

  const handleClose = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    if (setIsVisible) {
      setIsVisible(false)
    }
    event.preventDefault()
    setIsOpen(false)
  }

  const handleCopyLink = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
    copyLink()
    handleClose(event)
  }

  const handleEditLink = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
    editLink()
    handleClose(event)
  }

  const handleDeleteLink = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    event.preventDefault()
    deleteLink()
    handleClose(event)
  }

  const ariaLabelId = () => {
    return `link-dropdown-${linkTitle}`
  }

  return (
    <>
      <IconButton id={ariaLabelId()} aria-haspopup="true" onClick={handleClick} aria-label={`edit link ${linkTitle}`}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
        MenuListProps={{
          "aria-labelledby": ariaLabelId(),
          sx: { py: 0 },
        }}
      >
        <MenuItem onClick={handleCopyLink}>Copy Url</MenuItem>
        <MenuItem onClick={handleEditLink}>Edit link</MenuItem>
        <MenuItem onClick={handleDeleteLink}>Delete link</MenuItem>
      </Menu>
    </>
  )
}
