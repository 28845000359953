import { useEffect, useContext } from "react"
import { Steps } from "intro.js-react"
import styles from "./CustomNode.module.css"
import "intro.js/introjs.css"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// Contexts
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { ModalContext } from "contexts/ModalContext"
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

export const UserOnboardingTour = () => {
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { setModalState } = useContext(ModalContext)
  const { data } = useQuery(getActiveUserQuery)
  const { userOnboardingTourState, updateUserOnboardingTourState } = useContext(UserOnboardingTourContext)
  const { currentModeState } = useContext(LightDarkModeContext)
  const isDarkModeEnabled = currentModeState.palette?.mode === "dark"

  const stepData = [
    {
      // idx 0
      title: "Welcome To The Tour!",
      intro: "This tour will briefly show you around Linkidex. Desktop users can navigate with the arrow keys.",
    },
    {
      // idx 1
      element: "#nav-add-link-button",
      intro: "The 'New Link' button lets you add links to Linkidex",
    },
    {
      // idx 2
      element: "#outlined-adornment-link-url",
      intro: "Links must have URLs. Everything else is optional.",
    },
    {
      // idx 3
      element: "#outlined-adornment-link-url",
      intro: "A Link can belong to one category, and have multiple tags.",
    },
    {
      // idx 4
      element: "#LinkSearchList",
      intro: "Saved Links that satisfy your search query appear here.",
    },
    {
      // idx 5
      element: "#outlined-adornment-SearchLinks",
      intro: "This search bar lets you search links by title, url, categories and tags all at the same time.",
    },
    {
      // idx 6
      element: "#search-filters-and-return-to-search",
      intro:
        "Filters can be opened with the Icon at the top left of your screen, or by swiping from the left on mobile. ",
    },
    {
      // idx 7
      element: "#link-side-drawer-categories",
      intro: "Click here to view, edit, and delete your categories.",
    },
    {
      // idx 8
      element: "#link-side-drawer-tags",
      intro: "Click here to view, edit, and delete your tags.",
    },
    {
      // idx 9
      element: "#dnd-trees-personal",
      intro: "Select specific categories or tags to narrow down the links you are viewing.",
    },
    {
      // idx 10
      element: "#side-drawer-clear-filters",
      intro: "'Clear Filters' removes all of the filters you have selected",
    },
    {
      // idx 11
      element: "#open-settings-button",
      intro: "You can replay this tour from the account dropdown in the top right of your screen. ",
    },
  ]

  const handleOpenSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: true })
  }

  const handleCloseSideDrawer = () => {
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
  }

  const handleOpenCreateLinkModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.CREATE_LINK })
  }

  const handleCloseAllModals = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  useEffect(() => {
    // when current user changes (such as during login or logout) reset UserOnboardingTourState
    updateUserOnboardingTourState({
      hasSeenWelcome: false,
      isOpen: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.activeUser?.id])

  return (
    <>
      <Steps
        onBeforeChange={async (nextStepIdx) => {
          return new Promise((resolve) => {
            if (nextStepIdx === 1) {
              handleCloseAllModals()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 2) {
              handleOpenCreateLinkModal()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 3) {
              handleOpenCreateLinkModal()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 4) {
              handleCloseAllModals()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 6) {
              handleCloseSideDrawer()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 7) {
              handleOpenSideDrawer()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 10) {
              handleOpenSideDrawer()
              setInterval(resolve, 250)
            } else if (nextStepIdx === 11) {
              handleCloseSideDrawer()
              setInterval(resolve, 250)
            } else {
              setInterval(resolve, 0)
            }
          })
        }}
        enabled={userOnboardingTourState.isOpen}
        steps={stepData}
        initialStep={0}
        options={{
          tooltipClass: isDarkModeEnabled ? styles.tooltipClassDark : styles.tooltipClassLight,
          highlightClass: styles.highlightClass,
        }}
        onExit={() => {
          updateUserOnboardingTourState({
            hasSeenWelcome: true,
            isOpen: false,
          })
        }}
      />
    </>
  )
}
