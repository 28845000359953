import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

export const ExtensionLoginSuccessCard = () => {
  return (
    <Box>
      <Box textAlign="center">
        <Box
          component="img"
          sx={{
            my: 4,
            width: { xs: "124px", md: "124px%" },
          }}
          alt="Linkidex Logo"
          src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/linkidex_512_v3.png`}
        />
      </Box>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Login successful! Open the Linkidex extension to continue.
      </Typography>
      <Box textAlign="center">
        <Button sx={{ mt: 4 }} variant="outlined" color="error" onClick={() => window.close()}>
          Close
        </Button>
      </Box>
    </Box>
  )
}
