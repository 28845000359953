import React from "react"

// Components
import { BackToLinksSubHeader } from "components/BackToLinksSubHeader"
import { ExtensionLoginSuccessCard } from "components/ExtensionLoginSuccessCard"

export function GoToExtensionPage() {
  return (
    <>
      <BackToLinksSubHeader />
      <ExtensionLoginSuccessCard />
    </>
  )
}
