import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { useQuery } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// MUI
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import Divider from "@mui/material/Divider"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"

// Utils
import { ApiUtils } from "Utils/ApiUtils"
import { useIsLoggedIn } from "Utils/Utils"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

// Contexts
import { SelectedCategoriesContext } from "contexts/SelectedCategoriesContext"
import { SelectedTagsContext } from "contexts/SelectedTagsContext"
import { SideDrawerContext } from "contexts/SideDrawerContext"
import { UserOnboardingTourContext } from "contexts/UserOnboardingTourContext"
import { PersonalLinkCardContext } from "contexts/PersonalLinkCardContext"
import { ModalContext } from "contexts/ModalContext"
import { PersonalLinksSelectedContext } from "contexts/PersonalLinksSelectedContext"
import { SelectedOrganizationsContext } from "contexts/SelectedOrganizationsContext"
import { SnackBarContext } from "contexts/SnackBarContext"
import { FavoritesSelectedContext } from "contexts/FavoritesSelectedContext"
import { IsStrictSearchContext } from "contexts/IsStrictSearchContext"

// Components
import { LightDarkToggle } from "./LightDarkToggle"

interface NavbarDropdownProps {
  handleCloseUserMenu: (event: React.MouseEvent<HTMLElement>) => void
  handleOpenUserMenu: (event: React.MouseEvent<HTMLElement>) => void
  anchorElUser: null | HTMLElement
}

export function NavbarDropdown({ handleCloseUserMenu, handleOpenUserMenu, anchorElUser }: NavbarDropdownProps) {
  const { data } = useQuery(getActiveUserQuery)
  const { setSelectedCategoriesState } = useContext(SelectedCategoriesContext)
  const { setSelectedTagsState } = useContext(SelectedTagsContext)
  const { sideDrawerState, setSideDrawerState } = useContext(SideDrawerContext)
  const { updateUserOnboardingTourState } = useContext(UserOnboardingTourContext)
  const { setPersonalLinkCardState } = useContext(PersonalLinkCardContext)
  const { setModalState } = useContext(ModalContext)
  const { setPersonalLinksSelectedState } = useContext(PersonalLinksSelectedContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const { setSelectedOrganizationsState } = useContext(SelectedOrganizationsContext)
  const { setFavoritesSelectedState } = useContext(FavoritesSelectedContext)
  const { setIsStrictSearch } = useContext(IsStrictSearchContext)
  const isLoggedIn = useIsLoggedIn()

  let navigate = useNavigate()
  const client = useApolloClient()

  const logoutUser = async (event: React.MouseEvent<HTMLElement>) => {
    // close navbar
    handleCloseUserMenu(event)
    // delete session
    ApiUtils.deleteSession()
    // clear all React Contexts
    setSelectedCategoriesState({})
    setSelectedTagsState({})
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
    setModalState({ isOpen: false, modalType: "" })
    setPersonalLinksSelectedState(false)
    setSnackBarState({ isOpen: false, snackBarType: SNACK_BAR_TYPES.SUCCESS, message: "" })
    setSelectedOrganizationsState({})
    setPersonalLinkCardState(false)
    updateUserOnboardingTourState({ hasSeenWelcome: false, isOpen: false })
    setFavoritesSelectedState(false)
    setIsStrictSearch(false)
    // clear graphQL data
    await client.clearStore()
    // refetch current user, which will trigger re-render
    client.refetchQueries({ include: [getActiveUserQuery] }) // Refetch active user
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.tabs.query({ url: `${process.env.PUBLIC_URL}/*` }, (tabs) => {
        tabs.forEach((tab) => {
          if (tab && tab.id) {
            chrome.tabs.sendMessage(tab.id, { type: "LOGOUT_EVENT" })
          }
        })
      })
      chrome.storage.local.clear()
    }
  }

  const startUserOnboardingTour = (event: React.MouseEvent<HTMLElement>) => {
    navigate("/links")
    setSideDrawerState({ ...sideDrawerState, isOpen: false })
    setPersonalLinkCardState(true)
    updateUserOnboardingTourState({ hasSeenWelcome: true, isOpen: true })
    handleCloseUserMenu(event)
  }

  // don't render account dropdown if no current account
  if (!isLoggedIn) {
    return <></>
  }
  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} id="open-settings-button">
          <Avatar sx={{ bgcolor: "rgba(0, 0, 0, 0)", color: "white" }} alt={data?.activeUser?.email}>
            <AccountCircleIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        disableScrollLock
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          key="account"
          onClick={(event) => {
            handleCloseUserMenu(event)
            navigate("/account")
          }}
        >
          <Typography textAlign="center">Account</Typography>
        </MenuItem>

        <MenuItem
          key="download"
          onClick={(event) => {
            handleCloseUserMenu(event)
            navigate("/download")
          }}
        >
          <Typography textAlign="center">Download</Typography>
        </MenuItem>

        <LightDarkToggle
          onClick={(event) => {
            handleCloseUserMenu(event)
          }}
        />
        <MenuItem
          key="links"
          onClick={(event) => {
            handleCloseUserMenu(event)
            navigate("/links")
          }}
        >
          {" "}
          <Typography textAlign="center">Links</Typography>
        </MenuItem>

        <MenuItem key="tour" onClick={startUserOnboardingTour} id="start-onboarding-tour">
          <Typography textAlign="center">Onboarding Tour</Typography>
        </MenuItem>

        <MenuItem
          key="organizations"
          onClick={(event) => {
            handleCloseUserMenu(event)
            navigate("/organizations")
          }}
        >
          <Typography textAlign="center">Organizations</Typography>
        </MenuItem>

        <Divider />

        <MenuItem key="logout" onClick={logoutUser}>
          <Typography textAlign="center">Log out</Typography>
        </MenuItem>
      </Menu>
    </Box>
  )
}
