import { Navigate } from "react-router-dom"
import { useIsLoggedIn } from "Utils/Utils"
// Components
import { LandingHowTo } from "components/LandingHowTo"
import { ExtensionLogin } from "components/ExtensionLogin"
import { BottomLegalLinks } from "components/BottomLegalLinks"
import { useIsExtension } from "Utils/Utils"

// Contexts

export function LandingPage() {
  const isLoggedIn = useIsLoggedIn()
  const isExtension = useIsExtension()

  if (isLoggedIn) {
    return <Navigate to="/links" />
  } else if (isExtension) {
    return (
      <>
        <ExtensionLogin />
      </>
    )
  }
  return (
    <>
      <LandingHowTo />
      <BottomLegalLinks />
    </>
  )
}
