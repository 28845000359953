/* eslint-disable no-restricted-globals */
import React, { useEffect } from "react"
import { useContext, useRef } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useApolloClient } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// MUI Components
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"

// Components
import { InputTextField } from "../InputTextField"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { debounceFunction } from "../../Utils/Utils"
import { ModalContext } from "../../contexts/ModalContext"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { MODAL_TYPES } from "components/Modal/ModalTypes"

// Styles
import { loginBoxStyle } from "./styles"

export interface LoginStateInterface {
  password: string
  email: string
  showPassword: boolean
  rememberMe: boolean
}

export function LoginBox() {
  const timeout = useRef<any>()
  const { setModalState } = useContext(ModalContext)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loginErrorText, setLoginErrorText] = React.useState<string>("")
  const [values, setValues] = React.useState<LoginStateInterface>({
    password: "",
    email: "",
    showPassword: false,
    rememberMe: false,
  })
  const client = useApolloClient()

  const handleChange = (prop: keyof LoginStateInterface) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleClickRememberMe = () => {
    setValues({
      ...values,
      rememberMe: !values.rememberMe,
    })
  }

  const handleOpen2FactorModal = (login_stage_two_code: string, remember_me: boolean) => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.SECOND_FACTOR,
      data: {
        login_stage_two_code: login_stage_two_code,
        remember_me: remember_me,
      },
    })
  }
  const handleOpenWebauthnModal = (login_stage_two_code: string, remember_me: boolean) => {
    setModalState({
      isOpen: true,
      modalType: MODAL_TYPES.WEBAUTHN_FACTOR,
      data: {
        login_stage_two_code: login_stage_two_code,
        remember_me: remember_me,
      },
    })
  }

  const handleLogin = () => {
    client.clearStore()
    setLoginErrorText("")
    ApiUtils.createSession({
      credential: values.email,
      password: values.password,
      rememberMe: values.rememberMe,
    }).then(
      (res: any) => {
        if (res.login_stage_two_code && res.two_factor_type === "two_factor") {
          handleOpen2FactorModal(res.login_stage_two_code, values.rememberMe)
        } else if (res.login_stage_two_code && res.two_factor_type === "webauthn") {
          handleOpenWebauthnModal(res.login_stage_two_code, values.rememberMe)
        } else {
          let intended = history?.state?.usr?.from
          client.refetchQueries({
            include: [getActiveUserQuery],
          })
          if (searchParams.get("source") === "extension") {
            navigate("/go_to_extension")
          } else if (intended) {
            navigate(intended)
          } else {
            navigate("/links")
          }
        }
      },
      (res: object) => {
        setLoginErrorText("Login failed! Please try again.")
      }
    )
  }

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        (event.srcElement.id === "outlined-adornment-email" || event.srcElement.id === "outlined-adornment-password")
      ) {
        event.preventDefault()
        debouncedHandleLogin()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const debouncedHandleLogin = () => {
    debounceFunction(timeout, handleLogin, DEBOUNCE_TIME)
  }

  const openSignUpModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.SIGN_UP })
  }

  return (
    <>
      <Box sx={loginBoxStyle}>
        <Typography variant="h4" textAlign="center" sx={{ my: 2 }}>
          Login
        </Typography>

        {/* input email */}
        <InputTextField
          name="email"
          helperText="email"
          handleChange={handleChange("email")}
          canToggleTextFieldVisibility={false}
          shouldShowTextField={true}
          handleClickShowTextField={() => {}}
          textField={values.email}
          error={!!loginErrorText}
          errorText=""
        />

        {/* input password */}
        <InputTextField
          name="password"
          helperText="password"
          handleChange={handleChange("password")}
          canToggleTextFieldVisibility={true}
          shouldShowTextField={values.showPassword}
          handleClickShowTextField={handleClickShowPassword}
          textField={values.password}
          error={!!loginErrorText}
          errorText=""
        />

        <FormGroup>
          <FormControlLabel
            control={<Checkbox sx={{ ml: 1 }} checked={values.rememberMe} onClick={handleClickRememberMe} />}
            label="Remember Me"
          />
        </FormGroup>

        <FormControl error={!!loginErrorText} sx={{ width: { xs: "100%", md: "auto" } }}>
          <Button variant="contained" onClick={debouncedHandleLogin}>
            Login
          </Button>
          <FormHelperText aria-live="polite">{loginErrorText}</FormHelperText>
        </FormControl>

        <br />
        <Button
          sx={{ mt: 1 }}
          variant="text"
          onClick={() => {
            navigate("/forgot_password")
          }}
        >
          Forgot Password
        </Button>

        <Divider sx={{ my: 1 }} />
        <Typography textAlign="center" sx={{ my: 2 }}>
          Don't have an account? Creating one is easy!
        </Typography>
        <Button variant="contained" color="success" onClick={() => openSignUpModal()}>
          Create Account
        </Button>
      </Box>
    </>
  )
}
