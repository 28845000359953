export const DEBOUNCE_TIME = 250
export const DEBOUNCE_TIME_LONG = 500
export const DEFAULT_DRAWER_WIDTH = 260
export const DRAWER_MIN_WIDTH = 260
export const DRAWER_MAX_WIDTH = 400
export const SEARCH_ROW_MOBILE = 76
export const SEARCH_ROW_DESKTOP = 28

export const FOLDER = "Folder"
export const CATEGORY = "Category"
export const TAG = "Tag"

export const THEME_KEY = "theme"
export const CURRENT_VERSION_KEY = "linkidex-fe-v" // localStorage

export const LINK_MAX_TITLE_LENGTH = 128
export const LINK_MAX_URL_LENGTH = 2048
export const LINK_MAX_DESCRIPTION_LENGTH = 255
export const HOSTNAME = "www.linkidex.com"
export const COLLAPSE_DEFAULT_TIMEOUT = { appear: 400, enter: 400, exit: 250 }

export const FAVORITE_COLOR = "#ec407a"
