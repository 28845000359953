import React from "react"
import { useContext } from "react"
import { useIsExtension } from "Utils/Utils"

// MUI
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import { SxProps } from "@mui/material"

// Components
import { ModalContext } from "contexts/ModalContext"

// Types
import { MODAL_TYPES } from "components/Modal/ModalTypes"

interface WebauthnRowInterface {
  textStyle: SxProps
}

export function WebauthnRow({ textStyle }: WebauthnRowInterface) {
  const { setModalState } = useContext(ModalContext)
  const isExtension = useIsExtension()

  const openModal = () => {
    setModalState({ isOpen: true, modalType: MODAL_TYPES.MANAGE_WEBAUTHN })
  }

  const buttonAction = () => {
    if (isExtension) {
      window.open(`${process.env.PUBLIC_URL}/account`, "_blank")
    } else {
      openModal()
    }
  }

  const buttonText = () => {
    if (isExtension) {
      return "Manage on linkidex.com"
    } else {
      return "Manage WebAuthn"
    }
  }

  return (
    <>
      {/* Left part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={8}>
        <Typography sx={textStyle} variant="h6">
          WebAuthn
        </Typography>
        <Typography sx={textStyle} variant="subtitle2">
          Advanced Two-Factor options such as Yubico keys.
        </Typography>
      </Grid>

      {/* Right part of row */}
      <Grid item sx={{ display: { marginTop: 20 } }} xs={12} sm={4}>
        <Button
          aria-label="manage-webauthn"
          onClick={buttonAction}
          variant="outlined"
          sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
        >
          {buttonText()}
        </Button>
      </Grid>
    </>
  )
}
