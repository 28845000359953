import React from "react"
import { useEffect, useState, useContext } from "react"
import { useMutation } from "@apollo/client"
import { useParams, useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"

// Contexts
import { SnackBarContext } from "contexts/SnackBarContext"

// MUI
import Typography from "@mui/material/Typography"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"

import { getActiveUserQuery, verifyEmailMutation } from "queries/queries"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const VerifyEmail = () => {
  const [isVerifying, setIsVerifying] = useState<Boolean>(false)
  const [isError, setIsError] = useState<Boolean>(false)
  const [isVerified, setIsVerified] = useState<Boolean>(false)

  const { setSnackBarState } = useContext(SnackBarContext)
  const { data } = useQuery(getActiveUserQuery)
  const navigate = useNavigate()

  const [verifyEmail] = useMutation(verifyEmailMutation)

  let { code } = useParams()

  useEffect(() => {
    if (code) {
      setIsVerifying(true)
      setIsError(false)
      verifyEmail({
        variables: {
          code: code,
        },
        refetchQueries: [{ query: getActiveUserQuery }],
      }).then(
        () => {
          setIsVerifying(false)
          setIsError(false)
          setIsVerified(true)

          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.SUCCESS,
            message: "Email Verified!",
          })
        },
        (res: any) => {
          setIsVerifying(false)
          setIsError(true)
          setIsVerified(false)

          setSnackBarState({
            isOpen: true,
            snackBarType: SNACK_BAR_TYPES.ERROR,
            message: "Email verification failed.",
          })
        }
      )
    } else {
      setIsVerifying(false)
      setIsError(true)
    }
    return () => {}
  }, [code, data?.activeUser?.id, setSnackBarState, verifyEmail])

  const isVerifyingMessage = () => {
    if (isVerifying) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h4">
            Verifying email, please wait...
          </Typography>
          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <CircularProgress />
          </Box>
        </>
      )
    }
  }

  const isErrorMessage = () => {
    if (isError) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            Something went wrong! Please make sure you have the entire link and try again.
          </Typography>
        </>
      )
    }
  }

  const isVerifiedMessage = () => {
    if (isVerified) {
      return (
        <>
          <Typography sx={{ pt: 3 }} textAlign="center" variant="h5">
            Your email has been verified!
          </Typography>

          <Box justifyContent="center" alignItems="center" sx={{ pt: 5, display: "flex" }}>
            <Button
              onClick={() => navigate("/links")}
              variant="outlined"
              color="success"
              sx={{ mt: { xs: 0, sm: 1.5 }, ml: { xs: 2, sm: 0 } }}
            >
              Back to Linkidex
            </Button>
          </Box>
        </>
      )
    }
  }

  return (
    <>
      {isVerifyingMessage()}
      {isErrorMessage()}
      {isVerifiedMessage()}
    </>
  )
}
