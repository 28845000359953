// This is used by Linkidex Chrome Extension to persist Apollo Data between sessions,
// so we don't have to re-fetch links every time the extension is closed & opened
export class ChromeStorageWrapper {
  private storage: chrome.storage.StorageArea

  constructor(storageType: "local" | "sync" = "local") {
    this.storage = chrome.storage[storageType]
  }

  async getItem(key: string): Promise<string | null> {
    return new Promise((resolve) => {
      this.storage.get(key, (result) => {
        resolve(result[key] || null)
      })
    })
  }

  async setItem(key: string, value: string): Promise<void> {
    return new Promise((resolve) => {
      this.storage.set({ [key]: value }, resolve)
    })
  }

  async removeItem(key: string): Promise<void> {
    return new Promise((resolve) => {
      this.storage.remove(key, resolve)
    })
  }
}
