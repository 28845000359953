import { useContext } from "react"
import { useTheme } from "@mui/material/styles"
import { DndProvider } from "react-dnd"
import { MultiBackend } from "react-dnd-multi-backend"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { TouchTransition, MouseTransition } from "react-dnd-multi-backend"

// mui
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardActionArea from "@mui/material/CardActionArea"
import Divider from "@mui/material/Divider"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import LibraryAddCheck from "@mui/icons-material/LibraryAddCheck"
import FilterNone from "@mui/icons-material/FilterNone"

// components
import { UserCategories } from "./UserCategories"
import { UserTags } from "./UserTags"
import { PersonalLinkCardContext } from "contexts/PersonalLinkCardContext"

interface PersonalLinksCardPropsInterface {
  isNotMobile: boolean
  togglePersonalLinks: () => void
  isPersonalLinksChecked: boolean
  isUserOnLinksPage: () => boolean
  clearSelectedTags: () => void
  clearSelectedCategories: () => void
  countOfSelectedCategories: () => number
  countOfSelectedTags: () => number
  toggleTag: (tagId: number) => void
  toggleCategory: (categoryId: number) => void
  isCategoryChecked: (categoryId: number) => boolean | undefined
  isTagChecked: (tagId: number) => boolean | undefined
}

export const PersonalLinksCard = (props: PersonalLinksCardPropsInterface) => {
  const {
    isNotMobile,
    togglePersonalLinks,
    isPersonalLinksChecked,
    isUserOnLinksPage,
    // clearSelectedTags,
    // clearSelectedCategories,
    // countOfSelectedCategories,
    // countOfSelectedTags,
    toggleTag,
    toggleCategory,
    isCategoryChecked,
    isTagChecked,
  } = props
  const { personalLinkCardState, setPersonalLinkCardState } = useContext(PersonalLinkCardContext)

  const theme = useTheme()

  const doNotRipple = (event: any) => {
    // this prevents the ripple effect from propagating to CardActionArea
    event.stopPropagation()
  }

  const determineOrgCheckedIcon = () => {
    if (isPersonalLinksChecked) {
      return <LibraryAddCheck color="primary" sx={{ fontSize: "23px" }} />
    } else {
      return <FilterNone sx={{ fontSize: "22px" }} />
    }
  }

  const determineTooltipText = () => {
    if (isPersonalLinksChecked) {
      return "currently searching all of your personal links"
    }
    return ""
  }

  const determineBorderColor = () => {
    if (isPersonalLinksChecked) {
      return theme.palette.primary.main
    }
    return theme.palette.divider
  }

  const CustomHTML5toTouch = {
    backends: [
      {
        id: "html5",
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: "touch",
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          scrollAngleRanges: [{ start: 0, end: 360 }],
        },
        preview: true,
        transition: TouchTransition,
      },
    ],
  }

  const determineTimeout = () => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      return {
        appear: 100,
        enter: 100,
        exit: 100,
      }
    }
    return "auto"
  }

  return (
    <Tooltip followCursor title={determineTooltipText()}>
      <Card
        sx={{
          mx: 1,
          my: 2,
          p: 0,
          borderRadius: 2,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: determineBorderColor(),
          boxShadow: 0,
        }}
      >
        <CardActionArea
          onClick={() => {
            setPersonalLinkCardState(!personalLinkCardState)
          }}
        >
          <CardHeader
            action={
              <Box justifyContent="right">
                <IconButton
                  component="span"
                  aria-label="Toggle Search"
                  onMouseDown={doNotRipple}
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.stopPropagation()
                    event.preventDefault()
                    togglePersonalLinks()
                  }}
                >
                  {determineOrgCheckedIcon()}
                </IconButton>
              </Box>
            }
            title="Personal Links"
            // subheader="September 14, 2016"
          />
        </CardActionArea>
        <Collapse in={personalLinkCardState} timeout={determineTimeout()} unmountOnExit>
          <Divider sx={{ mx: 2 }} />
          <Box sx={{ pl: 0 }}>
            <DndProvider backend={MultiBackend} options={CustomHTML5toTouch} context={window}>
              <UserCategories
                isDisabled={isPersonalLinksChecked}
                isNotMobile={isNotMobile}
                isUserOnLinksPage={isUserOnLinksPage()}
                toggleItem={toggleCategory}
                isItemChecked={isCategoryChecked}
              />
            </DndProvider>
            <DndProvider backend={MultiBackend} options={CustomHTML5toTouch} context={window}>
              <UserTags
                isDisabled={isPersonalLinksChecked}
                isNotMobile={isNotMobile}
                isUserOnLinksPage={isUserOnLinksPage()}
                toggleItem={toggleTag}
                isItemChecked={isTagChecked}
              />
            </DndProvider>
          </Box>
        </Collapse>
      </Card>
    </Tooltip>
  )
}
