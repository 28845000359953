// mui
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"

// Components
import { InputTextField } from "components/InputTextField"

interface RegisterNewWebauthnCredentialPropsInterface {
  closeModal: () => void
  handleNicknameChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputNickname: string
  registerNewWebauthnCredentialError: string
  debouncedHandleRegisterNewWebauthnCredential: () => void
}

export const RegisterNewWebauthnCredential = (
  props: RegisterNewWebauthnCredentialPropsInterface
) => {
  const {
    closeModal,
    handleNicknameChange,
    inputNickname,
    registerNewWebauthnCredentialError,
    debouncedHandleRegisterNewWebauthnCredential,
  } = props

  return (
    <>
      <Typography sx={{ mb: 1, textAlign: "center" }}>
        Add New Webauthn Credential
      </Typography>
      <Typography sx={{ my: 2 }}>
        Enter a unique name for your new authentication method.
      </Typography>

      <InputTextField
        autoComplete={"off"}
        name="Authenticator Nickname"
        helperText="Authenticator Nickname"
        handleChange={handleNicknameChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={inputNickname}
        error={!!registerNewWebauthnCredentialError}
        errorText={registerNewWebauthnCredentialError}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel`}
            >
              Close
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl
              error={false}
              sx={{ width: { xs: "100%", md: "auto" } }}
            >
              <Button
                variant="outlined"
                onClick={debouncedHandleRegisterNewWebauthnCredential}
                disabled={!inputNickname}
                color="info"
                aria-label={`remove two factor`}
              >
                Register New Credential
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
