import React, { useState, useContext, useEffect, useRef } from "react"
import { useMutation, useQuery } from "@apollo/client"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Alert from "@mui/material/Alert"
import { SelectChangeEvent } from "@mui/material/Select"

// Components
import { InputTextField } from "components/InputTextField"
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { SelectFolderForm } from "components/SelectFolderForm"
import { DropDownOption } from "components/SelectFieldDropdown"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { writeTagQuery, updateTagMutation, getFoldersQuery } from "queries/queries"
import { debounceFunction } from "Utils/Utils"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { FolderInterface } from "interfaces/Folders"
import { TAG } from "constants/Global"

interface EditTagModalInterface {
  id: number
  title: string
  description: string
}

export const EditTagModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const [titleError, setTitleError] = useState<string>("")
  const [descriptionError, setDescriptionError] = useState<string>("")
  const [errorText, setErrorText] = useState<string>(" ")
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [values, setValues] = useState<EditTagModalInterface>({
    id: modalState.data.id,
    title: modalState.data.title,
    description: modalState.data.description,
  })
  const { setSnackBarState } = useContext(SnackBarContext)

  const [editTag] = useMutation(updateTagMutation, {
    update(cache, { data: { updateTag } }) {
      cache.modify({
        id: cache.identify(updateTag),
        fields: {
          tags(existingCategories = []) {
            const newTagRef = cache.writeFragment({
              data: updateTag,
              fragment: writeTagQuery,
            })
            return [...existingCategories, newTagRef]
          },
        },
      })
    },
  })
  const { loading, error, data } = useQuery(getFoldersQuery)

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const buildDefaultDropdownOption = () => {
    if (modalState.data.folderId) {
      return {
        name: modalState.data.folderTitle,
        value: modalState.data.folderId.toString(),
      }
    }
    return null
  }

  const [dropdownOption, setDropdownOption] = useState<DropDownOption | null>(buildDefaultDropdownOption())

  const handleChange = (prop: keyof EditTagModalInterface) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSelect = (event: SelectChangeEvent, value: DropDownOption) => {
    setDropdownOption(value)
  }

  const handleEditTag = () => {
    editTag({
      variables: {
        id: values.id,
        title: values.title,
        description: values.description,
        folderId: dropdownOption?.value,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Tag Updated!",
        })
        closeModal()
      },
      (res: any) => {
        setTitleError("")
        setDescriptionError("")
        if (res && res.message) {
          setErrorText(res.message)
        } else {
          setErrorText("Something went wrong, please try again.")
        }
      }
    )
  }

  const debouncedHandleEditTag = () => {
    debounceFunction(timeout, handleEditTag, DEBOUNCE_TIME)
  }

  useEffect(() => {
    let tempTitleError = ""
    let tempDescriptionError = ""

    if (values.title.length < 3) {
      tempTitleError = "Title too short."
    }
    if (values.title.length > 64) {
      tempTitleError = "Title too long."
    }
    if (values.description && values.description.length > 128) {
      tempDescriptionError = "Description too long!"
    }

    if (values.title.length === 0) {
      setIsSubmitDisabled(true)
    } else if (!tempTitleError && !tempDescriptionError) {
      setErrorText("")
      setTitleError("")
      setDescriptionError("")
      setIsSubmitDisabled(false)
    } else {
      setIsSubmitDisabled(true)
      setTitleError(tempTitleError)
      setDescriptionError(tempDescriptionError)
    }
  }, [values])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        (event.srcElement.id === "outlined-adornment-tag-title" ||
          event.srcElement.id === "outlined-adornment-tag-description")
      ) {
        event.preventDefault()
        if (isSubmitDisabled) {
          return
        }
        debouncedHandleEditTag()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isSubmitDisabled])

  const buildDropdownOptions = () => {
    if (loading || error) {
      return []
    }

    let folderData = data.folders.filter((folder: FolderInterface) => {
      // folder.organization?.id could be null and modalState.data.organization?.id undefined, must use ==
      // eslint-disable-next-line eqeqeq
      return folder.folderType === TAG && folder.organization?.id == modalState.data.organization?.id
    })

    return folderData.map((folder: FolderInterface) => {
      return { name: folder.title, value: folder.id }
    })
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label={`Edit tag ${values.title}`}
      >
        Edit Tag
      </Typography>

      {modalState.data.organization && (
        <Alert severity="info" variant="outlined" sx={{ mb: 1 }}>
          This tag belongs to <strong>{modalState.data.organization.name}</strong>
        </Alert>
      )}

      <InputTextField
        autoFocus={false}
        autoComplete={"off"}
        name="tag-title"
        helperText="Title*"
        handleChange={handleChange("title")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.title}
        error={!!titleError}
        errorText={titleError}
      />

      <Divider sx={{ my: 3 }}>Optional</Divider>

      <InputTextField
        autoComplete={"off"}
        name="tag-description"
        helperText="Description"
        handleChange={handleChange("description")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.description || ""}
        error={!!descriptionError}
        errorText={descriptionError}
      />

      <SelectFolderForm
        dropdownOptions={buildDropdownOptions()}
        title="Parent Folder"
        selectedOption={dropdownOption}
        handleSelect={handleSelect}
        folderType={TAG}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel edit tag ${values.title}`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl error={!!errorText} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Button
                variant="contained"
                onClick={debouncedHandleEditTag}
                disabled={isSubmitDisabled}
                aria-label={`Save changes to tag ${values.title}`}
              >
                Save Changes
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
