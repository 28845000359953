import React, { useContext, useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { getActiveUserQuery } from "queries/queries"
import { useApolloClient } from "@apollo/client"

// mui
import Typography from "@mui/material/Typography"
import FormControl from "@mui/material/FormControl"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"

// Components
import { InputTextField } from "components/InputTextField"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { ApiUtils } from "../../Utils/ApiUtils"
import { debounceFunction } from "Utils/Utils"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const LostAuthenticatorModal = () => {
  const client = useApolloClient()
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const navigate = useNavigate()

  const [inputCode, setInputCode] = useState("")
  const [inputErrorText, setInputErrorText] = useState("")

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  useEffect(() => {
    // enter submits form
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        debouncedHandleSubmitCode()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCode])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputCode(event.target.value)
  }

  const debouncedHandleSubmitCode = () => {
    debounceFunction(timeout, handleSubmitCode, DEBOUNCE_TIME)
  }

  const debouncedHandleSubmitRecoverAccount = () => {
    debounceFunction(timeout, handleSubmitRecoverAccountWithTwoFactor, DEBOUNCE_TIME)
  }

  const handleSubmitCode = () => {
    setInputErrorText("")
    ApiUtils.loginWithRecoveryCode({
      login_stage_two_code: modalState.data.login_stage_two_code,
      recovery_code: inputCode,
    }).then(
      (res: any) => {
        closeModal()
        // eslint-disable-next-line no-restricted-globals
        let intended = history?.state?.usr?.from
        client.refetchQueries({
          include: [getActiveUserQuery],
        })
        if (intended) {
          navigate(intended)
        } else {
          navigate("/links")
        }
      },
      (res: object) => {
        setInputErrorText("Login failed! Please try again.")
      }
    )
  }

  const handleSubmitRecoverAccountWithTwoFactor = () => {
    ApiUtils.recoverAccountWithTwoFactor({
      login_stage_two_code: modalState.data.login_stage_two_code,
    }).then(
      (res: any) => {
        closeModal()
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: `Recovery Initiated. Please check your email`,
        })
      },
      (res: object) => {}
    )
  }

  return (
    <>
      <Typography textAlign="center" variant="h5" sx={{ my: 2 }}>
        Recover Account
      </Typography>
      <Typography textAlign="center" sx={{ my: 2 }}>
        Please enter one of your recovery codes.
      </Typography>
      <InputTextField
        autoComplete={"off"}
        name="recovery-code"
        helperText="Recovery Code"
        handleChange={handleChange}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={inputCode}
        error={!!inputErrorText}
        errorText={inputErrorText}
      />

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel edit tag`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl error={!!inputErrorText} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Button
                variant="contained"
                onClick={debouncedHandleSubmitCode}
                disabled={false}
                aria-label={`Save changes to tag`}
              >
                Submit
              </Button>
            </FormControl>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography textAlign="center" variant="h6" sx={{ my: 2 }}>
        Don't have your recovery codes?
      </Typography>
      <Typography sx={{ my: 2 }}>
        Click 'recover account' to begin the recovery process via email. For security reasons this entire process takes
        several days.
      </Typography>

      <Box sx={{ textAlign: "center" }}>
        <Button variant="contained" color="warning" onClick={debouncedHandleSubmitRecoverAccount}>
          Recover Account
        </Button>
      </Box>
    </>
  )
}
