// mui
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"

export function FAQDisplay() {
  const doIHaveToPay = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          Do I have to pay to use Linkidex?
        </Typography>
        <Typography>
          You do not need to pay to use Linkidex. You can create an account on Linkidex for free and use our free tier
          forever.
        </Typography>
      </>
    )
  }

  const howIsPaymentProcessed = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          How is payment processed? Is it safe?
        </Typography>
        <Typography>
          Linkidex uses{" "}
          <Link href="https://stripe.com/" target="_blank">
            Stripe
          </Link>{" "}
          for billing. Stripe is one of the most popular and secure online payment providers available.
        </Typography>
      </>
    )
  }

  const whatHappensWhenICancel = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          What happens if I cancel my subscription?
        </Typography>
        <Typography>
          When your subscription ends you can continue to use Linkidex subject to the limitations of the free tier.
        </Typography>
      </>
    )
  }
  const canIGetARefund = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          Can I get a refund?
        </Typography>
        <Typography>
          We have a 30 day money back guarantee. If you aren't happy with Linkidex just{" "}
          <Link href="mailto:help@Linkidex.com">email</Link> us.
        </Typography>
      </>
    )
  }
  const possibleToImport = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          Can I import bookmarks from XYZ to Linkidex?
        </Typography>
        <Typography>
          Linkidex can{" "}
          <Link href="https://www.linkidex.com/blog/posts/how-to-import-bookmarks/" target="_blank">
            import
          </Link>{" "}
          bookmarks from browsers, and any other tool that generates 'html' bookmark files.{" "}
          <Link href="mailto:help@Linkidex.com">Email us</Link> if you need assistance.
        </Typography>
      </>
    )
  }
  const whatHappensToMyLinks = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          What happens to my links if Linkidex disappears?
        </Typography>
        <Typography>
          We aren't planning to go anywhere, but your links belong to you and you can{" "}
          <Link href="https://www.linkidex.com/blog/posts/how-to-export-bookmarks/" target="_blank">
            export
          </Link>{" "}
          them at any time.
        </Typography>
      </>
    )
  }
  const unansweredQuestion = () => {
    return (
      <>
        <Typography variant="h5" sx={{ my: 2 }}>
          I have a question that the FAQ didn't answer
        </Typography>
        <Typography>
          You can check out our{" "}
          <Link href="https://www.linkidex.com/blog/categories/linkidex-how-to/" target="_blank">
            how to
          </Link>{" "}
          articles for instructions on how to use some of Linkidex's features, or send us an{" "}
          <Link href="mailto:help@Linkidex.com">email</Link> and we will get back to you as soon as we can.
        </Typography>
      </>
    )
  }

  return (
    <Box
      sx={{
        mx: 2,
        my: 2,
        p: 2,
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "1000px",
      }}
    >
      <Box sx={{ margin: "auto", maxWidth: "1000px" }}>
        <Typography variant="h4" sx={{ my: 2, textAlign: "center" }}>
          Frequently Asked Questions
        </Typography>

        <Grid sx={{ pt: 2 }} container spacing={2}>
          <Grid item xs={12} sm={6}>
            {howIsPaymentProcessed()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {whatHappensWhenICancel()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {doIHaveToPay()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {canIGetARefund()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {whatHappensToMyLinks()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {possibleToImport()}
          </Grid>
          <Grid item xs={12} sm={6}>
            {unansweredQuestion()}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
