import { useEffect } from "react"
import { useApolloClient } from "@apollo/client"
import { getActiveUserQuery } from "queries/queries"

// MUI
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"

export function ExtensionLogin() {
  const client = useApolloClient()
  useEffect(() => {
    client.refetchQueries({
      include: [getActiveUserQuery],
    })
  }, [client])

  return (
    <>
      <Box>
        <Box textAlign="center">
          <Box
            component="img"
            sx={{
              my: 4,
              width: { xs: "124px", md: "124px%" },
            }}
            alt="Linkidex Logo"
            src={`https://linkidex-public-images.s3.us-west-1.amazonaws.com/linkidex_512_v3.png`}
          />
        </Box>
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          Welcome to Linkidex
        </Typography>
        <Typography sx={{ textAlign: "center" }}>
          Please login to continue. You will be redirected to your web browser.
        </Typography>

        <Box textAlign="center">
          <Button
            sx={{ my: 4 }}
            variant="outlined"
            color="success"
            onClick={() => window.open(`${process.env.PUBLIC_URL}/login?source=extension`)}
          >
            Login
          </Button>
        </Box>
      </Box>
    </>
  )
}
