import { useState, useEffect } from "react"

import { useTheme } from "@mui/material/styles"

import { DndProvider } from "react-dnd"
import { MultiBackend } from "react-dnd-multi-backend"
import { HTML5Backend } from "react-dnd-html5-backend"
import { TouchBackend } from "react-dnd-touch-backend"
import { TouchTransition, MouseTransition } from "react-dnd-multi-backend"

// mui
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardHeader from "@mui/material/CardHeader"
import CardActionArea from "@mui/material/CardActionArea"
import Divider from "@mui/material/Divider"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import LibraryAddCheck from "@mui/icons-material/LibraryAddCheck"
import FilterNone from "@mui/icons-material/FilterNone"

// components
import { UserCategories } from "./UserCategories"
import { UserTags } from "./UserTags"

// interfaces
import { OrganizationInterface } from "interfaces/Organization"

interface OrganizationCardPropsInterface {
  organization: OrganizationInterface
  idx: number
  isNotMobile: boolean
  toggleOrganization: (organizationId: string) => void
  isOrganizationChecked: (organizationId: string) => boolean | undefined
  isUserOnLinksPage: () => boolean
  clearSelectedTags: () => void
  clearSelectedCategories: () => void
  countOfSelectedCategories: () => number
  countOfSelectedTags: () => number
  toggleTag: (tagId: number) => void
  toggleCategory: (categoryId: number) => void
  isCategoryChecked: (categoryId: number) => boolean | undefined
  isTagChecked: (tagId: number) => boolean | undefined
}

export const OrganizationCard = (props: OrganizationCardPropsInterface) => {
  const {
    organization,
    // idx,
    isNotMobile,
    toggleOrganization,
    isOrganizationChecked,
    isUserOnLinksPage,
    // clearSelectedTags,
    // clearSelectedCategories,
    // countOfSelectedCategories,
    // countOfSelectedTags,
    toggleTag,
    toggleCategory,
    isCategoryChecked,
    isTagChecked,
  } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const chromeStorageKey = `org-card-open-${organization.id}`
  const theme = useTheme()

  useEffect(() => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.get([chromeStorageKey], (result) => {
        const openStatus = result[chromeStorageKey]?.openStatus
        if (typeof openStatus === "boolean") {
          setIsOpen(openStatus) // Update state with fetched value
        }
      })
    }
  }, [chromeStorageKey])

  const toggleIsOpen = () => {
    const newOpenStatus = !isOpen

    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      chrome.storage.local.set({ [chromeStorageKey]: { openStatus: newOpenStatus } }, () => {})
    }

    setIsOpen(newOpenStatus)
  }

  const disabledInfoButton = (organization: OrganizationInterface) => {
    return (
      <Tooltip followCursor title="Organization is full! Please contact an organization admin to add more seats.">
        <IconButton
          sx={{ p: 0, ml: 1 }}
          aria-label="Organization is full! Please contact an organization admin to add more seats."
        >
          <InfoOutlinedIcon tabIndex={-1} sx={{ fontSize: "24px" }} color="warning" />
        </IconButton>
      </Tooltip>
    )
  }

  const disabledAlertIcon = (organization: OrganizationInterface) => {
    if (organization.userOrganizationState === "disabled") {
      return disabledInfoButton(organization)
    }
    return null
  }

  const cardTitle = (organization: OrganizationInterface) => {
    return (
      <>
        {disabledAlertIcon(organization)}
        {organization.name}
        {/* {isOpen ? <ExpandMore sx={{ ml: 1 }} /> : <ExpandLess sx={{ ml: 1 }} />} */}
      </>
    )
  }

  const doNotRipple = (event: any) => {
    // this prevents the ripple effect from propagating to CardActionArea
    event.stopPropagation()
  }

  const determineOrgCheckedIcon = () => {
    if (isOrganizationChecked(organization.id)) {
      return <LibraryAddCheck color="primary" sx={{ fontSize: "23px" }} />
    } else {
      return <FilterNone sx={{ fontSize: "22px" }} />
    }
  }

  const determineTooltipText = () => {
    if (isOrganizationChecked(organization.id)) {
      return "currently searching everything in this organization"
    }
    return ""
  }

  const determineBorderColor = () => {
    if (isOrganizationChecked(organization.id)) {
      return theme.palette.primary.main
    }
    return theme.palette.divider
  }

  const CustomHTML5toTouch = {
    backends: [
      {
        id: "html5",
        backend: HTML5Backend,
        transition: MouseTransition,
      },
      {
        id: "touch",
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          scrollAngleRanges: [{ start: 0, end: 360 }],
        },
        preview: true,
        transition: TouchTransition,
      },
    ],
  }

  return (
    <Tooltip followCursor title={determineTooltipText()}>
      <Card
        key={`org-card-${organization.id}`}
        sx={{
          mx: 1,
          my: 2,
          p: 0,
          borderRadius: 2,
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: determineBorderColor(),
          boxShadow: 0,
        }}
      >
        <CardActionArea
          disabled={organization.userOrganizationState === "disabled"}
          onClick={() => {
            toggleIsOpen()
          }}
        >
          <CardHeader
            action={
              <Box justifyContent="right">
                <IconButton
                  component="span"
                  aria-label="Toggle Search"
                  onMouseDown={doNotRipple}
                  onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                    event.stopPropagation()
                    event.preventDefault()
                    toggleOrganization(organization.id)
                  }}
                >
                  {determineOrgCheckedIcon()}
                </IconButton>
              </Box>
            }
            title={cardTitle(organization)}
            // subheader="September 14, 2016"
          />
        </CardActionArea>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Divider sx={{ mx: 2 }} />
          <Box sx={{ pl: 0 }}>
            <DndProvider backend={MultiBackend} options={CustomHTML5toTouch} context={window}>
              <UserCategories
                isDisabled={isOrganizationChecked(organization.id)}
                isNotMobile={isNotMobile}
                isUserOnLinksPage={isUserOnLinksPage()}
                toggleItem={toggleCategory}
                isItemChecked={isCategoryChecked}
                organizationId={organization.id}
              />
            </DndProvider>
            <DndProvider backend={MultiBackend} options={CustomHTML5toTouch} context={window}>
              <UserTags
                isDisabled={isOrganizationChecked(organization.id)}
                isNotMobile={isNotMobile}
                isUserOnLinksPage={isUserOnLinksPage()}
                toggleItem={toggleTag}
                isItemChecked={isTagChecked}
                organizationId={organization.id}
              />
            </DndProvider>
          </Box>
        </Collapse>
      </Card>
    </Tooltip>
  )
}
