import { useIsLoggedIn } from "Utils/Utils"

import { Navigate, useLocation } from "react-router-dom"
import { Outlet } from "react-router"
import { EmailAlertBar } from "components/EmailAlertBar"

export const ProtectedRoutes = () => {
  const isLoggedIn = useIsLoggedIn()

  const location = useLocation()

  if (isLoggedIn) {
    return (
      <>
        <EmailAlertBar />
        <Outlet />
      </>
    )
  } else {
    return <Navigate to="/login" replace state={{ from: location }} />
  }
}
