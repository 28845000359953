import React from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { MemoryRouter } from "react-router-dom"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import "./index.css"
import AppThemeWrapper from "./AppThemeWrapper"

const root = createRoot(document.getElementById("root")!)

if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
  root.render(
    <React.StrictMode>
      <MemoryRouter>
        <AppThemeWrapper />
      </MemoryRouter>
    </React.StrictMode>
  )
} else {
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <AppThemeWrapper />
      </BrowserRouter>
    </React.StrictMode>
  )
}

serviceWorkerRegistration.register()
