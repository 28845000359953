import { useState, useContext, useRef } from "react"

// MUI Components
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import { useMutation } from "@apollo/client"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import {
  getFoldersQuery,
  // getCategoriesQuery,
  // getTagsQuery,
  deleteFolderMutation,
} from "queries/queries"
import { debounceFunction } from "Utils/Utils"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
// import { CATEGORY } from "constants/Global"

interface DeleteFolderModalInterface {
  id: number
  title: string
  description: string
}

export const DeleteFolderModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const [values] = useState<DeleteFolderModalInterface>({
    id: modalState.data.id,
    title: modalState.data.title,
    description: modalState.data.description,
  })

  const [deleteFolder] = useMutation(deleteFolderMutation, {
    update(cache, { data: { deleteFolder } }) {
      cache.evict({ id: cache.identify(deleteFolder) })
    },
  })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  // const determineRefetchQuery = () => {
  //   // returns category or tag query based on `contentType` so that we only need to run one of them instead of both
  //   // when deleting a folder from tags or categories
  //   if (modalState.data.contentType === CATEGORY) {
  //     return getCategoriesQuery
  //   }
  //   return getTagsQuery
  // }

  const handleDeleteFolder = () => {
    deleteFolder({
      variables: {
        id: values.id,
      },
      refetchQueries: [{ query: getFoldersQuery }],
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Folder deleted!",
        })
        closeModal()
      },
      (res: any) => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.ERROR,
          message: "Failed to delete Folder",
        })
        closeModal()
      }
    )
  }

  const debouncedHandleDeleteFolder = () => {
    debounceFunction(timeout, handleDeleteFolder, DEBOUNCE_TIME)
  }

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography
        textAlign="center"
        variant="h4"
        sx={{ my: 2 }}
        aria-live="polite"
        aria-label={`Cancel or Confirm Delete Folder ${values.title}`}
      >
        Delete Folder?
      </Typography>

      {modalState.data.organization && (
        <Alert severity="info" variant="outlined" sx={{ mb: 1 }}>
          This folder belongs to{" "}
          <strong>{modalState.data.organization.name}</strong>
        </Alert>
      )}

      <Typography
        variant="h5"
        sx={{
          flexGrow: 1,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flexBasis: "100%",
        }}
      >
        Title: {values.title}
      </Typography>

      <Typography variant="body1" color="text.secondary">
        Note: Anything within "{values.title}" will no longer be in a folder.
        This will not delete the contents of "{values.title}"
      </Typography>

      <Divider sx={{ my: 3 }}>
        <Typography>Description</Typography>
      </Divider>

      <Typography variant="body1" sx={{ wordWrap: "break-word" }}>
        {values.description}
      </Typography>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              variant="outlined"
              color="warning"
              onClick={closeModal}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Cancel delete Folder ${values.title}`}
            >
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={debouncedHandleDeleteFolder}
              sx={{ width: { xs: "100%", md: "auto" } }}
              aria-label={`Confirm delete Folder ${values.title}`}
            >
              Delete Folder
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
