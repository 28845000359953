import { useQuery } from "@apollo/client"
import { Navigate } from "react-router-dom"
import Typography from "@mui/material/Typography"
import { BackToLinksSubHeader } from "components/BackToLinksSubHeader"

// components
import { UpgradeUserTable } from "components/UpgradeUserTable"

// queries
import { getActiveUserQuery } from "queries/queries"

export function UpgradeUserPage() {
  const { loading, data } = useQuery(getActiveUserQuery, {
    fetchPolicy: "network-only",
  })

  const renderPage = () => {
    if (loading) {
      // wait if we are still fetching active user
      return <></>
    } else if (data && data.activeUser && !data?.activeUser?.hasActiveSubscription) {
      // display table if we have fetched active user
      return (
        <>
          <Typography variant="h3" sx={{ textAlign: "center", my: 2 }}>
            Upgrade Account
          </Typography>
          <UpgradeUserTable />
        </>
      )
    } else {
      // redirect user to their links
      return <Navigate to="/links" replace />
    }
  }

  return (
    <>
      <BackToLinksSubHeader />
      {renderPage()}
    </>
  )
}
