import { useContext } from "react"
import { Tree, NodeModel } from "@minoru/react-dnd-treeview"
import { sortTreeDataByTextAndPin } from "Utils/Utils"
import styles from "./App.module.css"
import { PinInterface } from "interfaces/Pin"

// MUI
import { ThemeProvider, ScopedCssBaseline } from "@mui/material"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// Contexts
import { LightDarkModeContext } from "contexts/LightDarkModeContext"

// Components
import { CustomNode } from "./CustomNode"
import { treeViewTheme } from "./TreeViewTheme"

// Constants
import { FOLDER, CATEGORY, TAG } from "constants/Global"

interface SideDrawerTreeViewProps {
  treeData: NodeModel<{
    pin: PinInterface
    contentType: typeof CATEGORY | typeof TAG | typeof FOLDER
  }>[]
  handleDrop?: any
  isDisabled?: boolean
}

export const SideDrawerTreeView = (props: SideDrawerTreeViewProps) => {
  const { treeData, handleDrop, isDisabled } = props
  const { currentModeState } = useContext(LightDarkModeContext)

  const currentMode = currentModeState.palette?.mode === "light" ? "light" : "dark"

  const determineContainerClass = () => {
    if (currentMode === "light") {
      return styles.lightContainer
    }
    return styles.darkContainer
  }

  const shouldEnableAnimatedExpand = () => {
    if (process.env.REACT_APP_BUILD_TYPE === "chrome") {
      return false
    }
    return true
  }

  if (treeData.length > 0) {
    return (
      <ThemeProvider theme={treeViewTheme(currentMode)}>
        <ScopedCssBaseline>
          <Box className="sideDrawerTree">
            <Tree
              tree={treeData}
              rootId={0}
              sort={(a, b) => sortTreeDataByTextAndPin(a, b)}
              render={(node, { depth, isOpen, onToggle }) => (
                <CustomNode node={node} depth={depth} isOpen={isOpen} onToggle={onToggle} isDisabled={isDisabled} />
              )}
              onDrop={handleDrop}
              enableAnimateExpand={shouldEnableAnimatedExpand()}
              classes={{
                root: styles.treeRoot,
                draggingSource: styles.draggingSource,
                dropTarget: styles.dropTarget,
                container: determineContainerClass(),
              }}
            />
          </Box>
        </ScopedCssBaseline>
      </ThemeProvider>
    )
  }

  return (
    <Typography variant={"subtitle1"} sx={{ pl: 1, pb: 1 }} color="text.secondary">
      - empty -
    </Typography>
  )
}
