import React, { useContext, useState, useRef, useEffect } from "react"
import { useQuery } from "@apollo/client"

import { getActiveUserQuery } from "queries/queries"
import * as WebAuthnJSON from "@github/webauthn-json"

// mui
import Typography from "@mui/material/Typography"
import Alert from "@mui/material/Alert"

// Components
import { CloseModalButton } from "components/Modal/CloseModalButton"
import { ShowWebauthnCredentials } from "./ShowWebauthnCredentials"
import { RegisterNewWebauthnCredential } from "./RegisterNewWebauthnCredential"
import { PasswordChallenge } from "../PasswordChallenge"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Utils
import { ApiUtils } from "Utils/ApiUtils"
import { debounceFunction, supportsWebauthn } from "Utils/Utils"

// Types
import { DEBOUNCE_TIME } from "constants/Global"
import { CredentialResponse } from "interfaces/Apis"
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"

export const ManageWebauthnModal = () => {
  const timeout = useRef<any>()
  const { setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const { data } = useQuery(getActiveUserQuery)

  const [inputPassword, setInputPassword] = useState("")
  const [inputNickname, setInputNickname] = useState("")
  const [
    registerNewWebauthnCredentialError,
    setRegisterNewWebauthnCredentialError,
  ] = useState("")
  const [inputErrorText, setInputErrorText] = useState("")
  const [
    isRegisterNewWebauthnCredentialOpen,
    setIsRegisterNewWebauthnCredentialOpen,
  ] = useState(false)

  const [userWebauthnCredentials, setUserWebauthnCredentials] =
    useState<CredentialResponse>({ credentials: [], login_stage_two_code: "" })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  useEffect(() => {
    // enter submits password
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        !userWebauthnCredentials.login_stage_two_code &&
        !isRegisterNewWebauthnCredentialOpen
      ) {
        event.preventDefault()
        debouncedHandleSubmitPassword()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputPassword, isRegisterNewWebauthnCredentialOpen])

  useEffect(() => {
    // enter submits create Webauthn Credential
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        isRegisterNewWebauthnCredentialOpen &&
        !!inputNickname
      ) {
        event.preventDefault()
        debouncedHandleRegisterNewWebauthnCredential()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputNickname, isRegisterNewWebauthnCredentialOpen])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputPassword(event.target.value)
  }

  const handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputNickname(event.target.value)
  }

  const debouncedHandleSubmitPassword = () => {
    debounceFunction(timeout, handleSubmitPassword, DEBOUNCE_TIME)
  }

  const debouncedHandleDeleteWebauthnCredential = (id: string) => {
    debounceFunction(
      timeout,
      () => {
        handleDeleteWebauthnCredential(id)
      },
      DEBOUNCE_TIME
    )
  }

  const debouncedHandleRegisterNewWebauthnCredential = () => {
    debounceFunction(
      timeout,
      handleRegisterNewWebauthnCredential,
      DEBOUNCE_TIME
    )
  }

  const handleSubmitPassword = () => {
    setInputErrorText("")
    ApiUtils.getWebauthnDetails({
      password: inputPassword,
    }).then(
      (res: CredentialResponse) => {
        setUserWebauthnCredentials(res)
      },
      (res: object) => {
        setInputErrorText("Validation failed! Please try again.")
      }
    )
  }

  const handleRegisterNewWebauthnCredential = () => {
    ApiUtils.createWebauthnChallenge(
      userWebauthnCredentials.login_stage_two_code
    ).then(
      (res: any) => {
        WebAuthnJSON.create({ publicKey: res }).then(
          (credential: any) => {
            ApiUtils.createWebauthnCredential({
              credential: credential,
              nickname: inputNickname,
              login_stage_two_code:
                userWebauthnCredentials.login_stage_two_code,
            }).then(
              (res: CredentialResponse) => {
                setUserWebauthnCredentials(res)
                setIsRegisterNewWebauthnCredentialOpen(false)
                setInputNickname("")
                setSnackBarState({
                  isOpen: true,
                  snackBarType: SNACK_BAR_TYPES.SUCCESS,
                  message: "Created Webauthn Credential.",
                })
              },
              (res: object) => {
                setRegisterNewWebauthnCredentialError(
                  "Validation failed! Please try again."
                )
              }
            )
          },

          (errorResponse: object) => {
            setRegisterNewWebauthnCredentialError(
              "Validation failed! Please try again."
            )
          }
        )
      },
      (errorResponse: object) => {
        setRegisterNewWebauthnCredentialError(
          "Validation failed! Please try again."
        )
      }
    )
  }

  const handleDeleteWebauthnCredential = (id: string) => {
    ApiUtils.deleteWebauthnDetail({
      id: id,
      login_stage_two_code: userWebauthnCredentials.login_stage_two_code,
    }).then(
      (res: CredentialResponse) => {
        setUserWebauthnCredentials(res)
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Removed Webauthn Credential.",
        })
      },
      (res: object) => {}
    )
  }

  const determineModalBody = () => {
    if (
      userWebauthnCredentials.login_stage_two_code &&
      isRegisterNewWebauthnCredentialOpen
    ) {
      return (
        <RegisterNewWebauthnCredential
          closeModal={closeModal}
          handleNicknameChange={handleNicknameChange}
          registerNewWebauthnCredentialError={
            registerNewWebauthnCredentialError
          }
          inputNickname={inputNickname}
          debouncedHandleRegisterNewWebauthnCredential={
            debouncedHandleRegisterNewWebauthnCredential
          }
        />
      )
    } else if (
      userWebauthnCredentials.login_stage_two_code &&
      !isRegisterNewWebauthnCredentialOpen
    ) {
      return (
        <ShowWebauthnCredentials
          closeModal={closeModal}
          setIsRegisterNewWebauthnCredentialOpen={
            setIsRegisterNewWebauthnCredentialOpen
          }
          webauthnCredentials={userWebauthnCredentials}
          debouncedHandleDeleteWebauthnCredential={
            debouncedHandleDeleteWebauthnCredential
          }
        />
      )
    } else {
      return (
        <PasswordChallenge
          closeModal={closeModal}
          debouncedHandleSubmitPassword={debouncedHandleSubmitPassword}
          handleChange={handleChange}
          inputPassword={inputPassword}
          inputErrorText={inputErrorText}
        />
      )
    }
  }
  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }}>
        Manage Webauthn
      </Typography>
      {!data?.activeUser?.secondFactorEnabled && (
        <Alert variant="outlined" severity="error" sx={{ my: 1 }}>
          Not all devices support Webauthn. Please consider activating
          Two-Factor so you don't get locked out of Linkidex!
        </Alert>
      )}
      {!supportsWebauthn() && (
        <Alert variant="outlined" severity="error" sx={{ my: 1 }}>
          You browser does not support Webauthn. Updating it may solve this
          issue.
        </Alert>
      )}
      {determineModalBody()}
    </>
  )
}
