import React, { useState, useContext, useEffect, useRef } from "react"
import { useQuery, useLazyQuery, useMutation } from "@apollo/client"

// MUI Components
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import FormHelperText from "@mui/material/FormHelperText"
import FormControl from "@mui/material/FormControl"
import Divider from "@mui/material/Divider"
import Alert from "@mui/material/Alert"
import Tooltip from "@mui/material/Tooltip"
import Collapse from "@mui/material/Collapse"
import Link from "@mui/material/Link"
import { SelectChangeEvent } from "@mui/material/Select"

// Components
import { InputTextField } from "components/InputTextField"
import { LinkTagList } from "components/LinkTagList"
import { HorizontalTagList } from "components/HorizontalTagList"
import { SelectCategoryForm } from "components/SelectCategoryForm"
import { CloseModalButton } from "components/Modal/CloseModalButton"

// Contexts
import { ModalContext } from "contexts/ModalContext"
import { SnackBarContext } from "contexts/SnackBarContext"

// Types
import { SNACK_BAR_TYPES } from "components/SnackBar/SnackBarTypes"
import { CategoryInterface } from "interfaces/Category"
import { OrganizationInterface } from "interfaces/Organization"
import { DropDownOption } from "components/SelectFieldDropdown"
import { DEBOUNCE_TIME } from "constants/Global"
import { LINK_MAX_DESCRIPTION_LENGTH, LINK_MAX_URL_LENGTH, LINK_MAX_TITLE_LENGTH } from "constants/Global"

// Utils
import { debounceFunction, isValidUrl } from "Utils/Utils"
import {
  editLinkMutation,
  writeLinkQuery,
  getCategoriesQuery,
  getOpenGraphDataQuery,
  getAiCategoriesAndTagsQuery,
  getActiveUserQuery,
} from "queries/queries"

interface EditLinkModalInterface {
  id: number
  title: string
  url: string
  description: string
  category?: CategoryInterface
  organization?: OrganizationInterface
}

export const EditLinkModal = () => {
  const timeout = useRef<any>()
  const { modalState, setModalState } = useContext(ModalContext)
  const { setSnackBarState } = useContext(SnackBarContext)
  const { loading, error, data } = useQuery(getCategoriesQuery)
  const getActiveUserResponse = useQuery(getActiveUserQuery)
  const [getOpenGraphData] = useLazyQuery(getOpenGraphDataQuery)
  const [getAiCategoriesAndTags] = useLazyQuery(getAiCategoriesAndTagsQuery)
  const [autofillError, setAutofillError] = useState(false)
  const [autofillNotSubscribed, setAutofillNotSubscribed] = useState(false)
  const [aiSuggestedData, setAiSuggestedData] = useState({ tags: [], categories: [] })

  const buildDefaultDropdownOption = () => {
    if (modalState.data.category) {
      return {
        name: modalState.data.category?.title,
        value: modalState.data.category?.id,
      }
    }
    return null
  }

  const [dropdownOption, setDropdownOption] = useState<DropDownOption | null>(buildDefaultDropdownOption())
  const [urlError, setUrlError] = useState<JSX.Element | string>("")
  const [titleError, setTitleError] = useState<string>("")
  const [descriptionError, setDescriptionError] = useState<string>("")
  const [errorText, setErrorText] = useState<string>(" ")
  const [values, setValues] = useState<EditLinkModalInterface>({
    id: modalState.data.id,
    title: modalState.data.title,
    url: modalState.data.url,
    description: modalState.data.description,
  })

  const [editLink] = useMutation(editLinkMutation, {
    update(cache, { data: { updateLink } }) {
      cache.modify({
        id: cache.identify(updateLink),
        fields: {
          links(existingLinks = []) {
            const newLinkRef = cache.writeFragment({
              data: updateLink,
              fragment: writeLinkQuery,
            })
            return [...existingLinks, newLinkRef]
          },
        },
      })
    },
  })

  const closeModal = () => {
    setModalState({ isOpen: false, modalType: "" })
  }

  const handleChange = (prop: keyof EditLinkModalInterface) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSelect = (event: SelectChangeEvent, value: DropDownOption) => {
    setDropdownOption(value)
  }

  const buildDropdownOptions = () => {
    if (loading || error) {
      return []
    }

    const filteredCategories = data.categories.filter((category: CategoryInterface) => {
      if (!modalState.data.organization) {
        return !category.organization?.id
      } else {
        return category.organization?.id === modalState.data.organization.id
      }
    })

    return filteredCategories.map((category: CategoryInterface) => {
      return { name: category.title, value: category.id }
    })
  }

  const isSubmitDisabled = () => {
    return !!urlError || !!titleError || !!descriptionError
  }

  const handleEditLink = () => {
    editLink({
      variables: {
        id: values.id,
        url: values.url,
        title: values.title,
        description: values.description,
        categoryId: dropdownOption?.value,
      },
    }).then(
      () => {
        setSnackBarState({
          isOpen: true,
          snackBarType: SNACK_BAR_TYPES.SUCCESS,
          message: "Link Updated!",
        })
        closeModal()
      },
      (res: any) => {
        setTitleError("")
        setUrlError("")
        setDescriptionError("")
        if (res && res.error) {
          setErrorText(res.error)
        } else {
          setErrorText("Error - Something went wrong, please try again.")
        }
      }
    )
  }

  const autoFillDetails = () => {
    // if (getActiveUserResponse.data?.activeUser?.hasActiveSubscription) {
    getOpenGraphData({ variables: { url: values.url } }).then(
      (response) => {
        if (response.error) {
          setErrorText("failed to fetch data")
        } else if (response.data && response.data.openGraphData) {
          setValues({
            ...values,
            title: response.data.openGraphData.title || "",
            description: response.data.openGraphData.description?.substring(0, LINK_MAX_DESCRIPTION_LENGTH) || "",
          })
        } else {
          setAutofillError(true)
        }
      },
      (res: any) => {
        setAutofillError(true)
      }
    )
    // } else {
    //   setAutofillNotSubscribed(true)
    // }
  }

  const suggestCategoryAndTags = () => {
    if (getActiveUserResponse.data?.activeUser?.hasActiveSubscription) {
      getAiCategoriesAndTags({ variables: { url: values.url } }).then(
        (response) => {
          if (response.error) {
            setErrorText("failed to fetch data")
          } else if (response.data && response.data.aiCategoryAndTags) {
            setAiSuggestedData({
              tags: response.data.aiCategoryAndTags.tags || [],
              categories: response.data.aiCategoryAndTags.categories || [],
            })
          } else {
            setAutofillError(true)
          }
        },
        (res: any) => {
          setAutofillError(true)
        }
      )
    } else {
      setAutofillNotSubscribed(true)
    }
  }

  const debouncedHandleEditLink = () => {
    debounceFunction(timeout, handleEditLink, DEBOUNCE_TIME)
  }

  const debouncedAutoFillDetails = () => {
    debounceFunction(timeout, autoFillDetails, 250)
  }

  const debouncedSuggestCategoryAndTags = () => {
    debounceFunction(timeout, suggestCategoryAndTags, 250)
  }

  const httpUpdateLink = () => {
    setValues((prevData) => ({ ...prevData, url: `http://${values.url}` }))
  }

  const httpsUpdateLink = () => {
    setValues((prevData) => ({ ...prevData, url: `https://${values.url}` }))
  }

  const httpMissingError = () => {
    return (
      <>
        Invalid URL - Must begin with{" "}
        <Link sx={{ cursor: "pointer", color: "warning.main" }} onClick={httpUpdateLink}>
          http://
        </Link>{" "}
        or{" "}
        <Link sx={{ cursor: "pointer", color: "warning.main" }} onClick={httpsUpdateLink}>
          https://
        </Link>{" "}
      </>
    )
  }

  useEffect(() => {
    // validate URL
    if (values.url.length < 3) {
      setUrlError(httpMissingError())
    } else if (!isValidUrl(values.url)) {
      setUrlError(httpMissingError())
    } else if (values.url.length > LINK_MAX_URL_LENGTH) {
      setUrlError(`URL too long, limit is ${LINK_MAX_URL_LENGTH} characters`)
    } else {
      setUrlError("")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.url])

  useEffect(() => {
    // validate title
    if (values.title.length > LINK_MAX_TITLE_LENGTH) {
      setTitleError("Title too long.")
    } else {
      setTitleError("")
    }
  }, [values.title])

  useEffect(() => {
    // validate description
    if (values.description && values.description.length > LINK_MAX_DESCRIPTION_LENGTH) {
      setDescriptionError("Description too long!")
    } else {
      setDescriptionError("")
    }
  }, [values.description])

  useEffect(() => {
    const listener = (event: any) => {
      if (
        (event.code === "Enter" || event.code === "NumpadEnter") &&
        (event.srcElement.id === "outlined-adornment-link-url" ||
          event.srcElement.id === "outlined-adornment-link-title" ||
          event.srcElement.id === "outlined-adornment-link-description")
      ) {
        event.preventDefault()
        if (isSubmitDisabled()) {
          return
        }
        debouncedHandleEditLink()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, dropdownOption])

  return (
    <>
      <CloseModalButton closeModal={closeModal} />
      <Typography textAlign="center" variant="h4" sx={{ my: 2 }} aria-live="polite">
        Edit Link
      </Typography>

      {modalState.data.organization && (
        <Alert severity="info" variant="outlined" sx={{ mb: 1 }}>
          This link belongs to <strong>{modalState.data.organization.name}</strong>
        </Alert>
      )}

      <InputTextField
        autoFocus={false}
        autoComplete="off"
        name="link-url"
        helperText="URL*"
        handleChange={handleChange("url")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.url}
        error={!!urlError}
        errorText={urlError}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Tools:</Typography>
      </Divider>

      <Grid container sx={{ my: 2 }} spacing={1}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Tooltip followCursor title="Fetch title and description from URL's web page">
              <Button
                disabled={!!urlError}
                variant="outlined"
                color="primary"
                onClick={debouncedAutoFillDetails}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Autofill Details
              </Button>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row-reverse",
            }}
          >
            <Tooltip followCursor title="Ask AI to suggest categories and tags">
              <Button
                disabled={!!urlError}
                variant="outlined"
                color="primary"
                onClick={debouncedSuggestCategoryAndTags}
                sx={{ width: { xs: "100%", md: "auto" } }}
              >
                Suggest Category & Tags
              </Button>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }}>
        <Typography>Details:</Typography>
      </Divider>

      <Collapse in={autofillError} sx={{ px: { xs: 2, md: 2 } }}>
        <Alert sx={{ mt: 1 }} variant="standard" severity="error">
          <Typography>Unable to autofill this Url's details</Typography>
        </Alert>
      </Collapse>

      <Collapse in={autofillNotSubscribed} sx={{ px: { xs: 2, md: 2 } }}>
        <Alert sx={{ mt: 1 }} variant="standard" severity="warning">
          <Typography>Please subscribe to use this feature.</Typography>
        </Alert>
      </Collapse>

      <InputTextField
        name="link-title"
        autoComplete="off"
        helperText="Title"
        handleChange={handleChange("title")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.title || ""}
        error={!!titleError}
        errorText={titleError}
      />

      <InputTextField
        name="link-description"
        autoComplete="off"
        helperText="Description"
        handleChange={handleChange("description")}
        canToggleTextFieldVisibility={false}
        shouldShowTextField={true}
        handleClickShowTextField={() => {}}
        textField={values.description || ""}
        error={!!descriptionError}
        errorText={descriptionError}
        multiline={true}
        maxRows={4}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Category:</Typography>
      </Divider>

      <SelectCategoryForm
        dropdownOptions={buildDropdownOptions()}
        title="Category"
        suggestions={aiSuggestedData.categories}
        selectedOption={dropdownOption}
        handleSelect={handleSelect}
        defaultOrganizationId={modalState.data.organization?.id}
      />

      <Divider sx={{ my: 3 }}>
        <Typography>Tags:</Typography>
      </Divider>

      <LinkTagList
        suggestions={aiSuggestedData.tags}
        organizationId={modalState.data.organization?.id}
        selectedTags={modalState.data.tags}
        linkId={values.id}
      />

      <HorizontalTagList tags={modalState.data.tags} linkId={values.id} shouldShowDelete={true} />

      <Divider sx={{ my: 2 }}></Divider>

      <Grid container sx={{ mt: 2, px: 1 }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button variant="outlined" color="warning" onClick={closeModal} sx={{ width: { xs: "100%", md: "auto" } }}>
              Cancel
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              mt: { xs: 2, md: 0 },
              display: "flex",
              flexDirection: { xs: "row", md: "row-reverse" },
            }}
          >
            <FormControl error={!!errorText} sx={{ width: { xs: "100%", md: "auto" } }}>
              <Button variant="contained" onClick={debouncedHandleEditLink} disabled={isSubmitDisabled()}>
                Save Changes
              </Button>
              <FormHelperText aria-live="polite">{errorText}</FormHelperText>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
